<script lang="ts">
  // import { goto } from "@roxi/routify";
  import Icon from "$lib/components/Icon.svelte";
  import { hapticsImpactMedium } from "src/utils/haptics";
  import { isOnline } from "$lib/stores/isOnline.store";
  import iOSModal from "$lib/components/system/iOS/Modal";
  import { Capacitor } from "@capacitor/core";
  import { navigate } from "svelte-routing/src/history";
  export let isBusinessPage;
  export let text_color;
  export let qrIsVisible;
  export let voucherExist;
  export let demo;
  export let businessType;
  export let name;

  const gotoPage = (path: string) => {
    // $goto(`/page/[id]/`, {
    //   id: path,
    // });

    // if (Capacitor.getPlatform() == "ios") {
    // iOSModal.presentModal({
    // url: `https://meloncards.app/page/${path.replace(" ", "%20")}`,
    // });
    // } else {
    // window.location.href = `/page/${path}/`;
    navigate(`/page/${path}/`);
    // }
  };
</script>

<div
  on:keydown
  class="w-full flex flex-col"
  on:click={async () => await hapticsImpactMedium()}
>
  {#if isBusinessPage}
    {#if qrIsVisible}
      <button
        class="btn flex btn-ghost border-{text_color} border-opacity-20 bg-{text_color} border-none bg-opacity-20 text-white"
        on:click={() => (qrIsVisible = false)}
      >
        Close
      </button>
    {:else}
      <button
        class="btn flex btn-ghost border-{text_color} border-opacity-20 bg-{text_color} bg-opacity-20 text-white"
        on:click={() => (qrIsVisible = true)}
      >
        {#if $voucherExist.isLoading}
          loading
        {:else if $voucherExist.isError}
          something went wrong
        {:else if $voucherExist.data.length}
          Tap to redeem voucher
        {:else}
          <span class="absolute left-1/2 -translate-x-1/2">Tap to stamp</span>
          <span class="flex-auto" />
          <span class="animate-pulse">
            <Icon type="FingerPrintIconOutline" />
          </span>
        {/if}
      </button>
    {/if}
  {:else if demo}
    <button
      class="btn flex btn-ghost border-{text_color} !border-none border-opacity-20 bg-{text_color} bg-opacity-20 text-white"
      on:click={() => (qrIsVisible = !qrIsVisible)}
    >
      {#if qrIsVisible}
        Close
      {:else}
        <span class="absolute left-1/2 -translate-x-1/2">Tap to stamp</span>
        <span class="flex-auto" />
        <span class="animate-pulse">
          <Icon type="FingerPrintIconOutline" />
        </span>
      {/if}
    </button>
  {:else if $businessType.isLoading}
    loading..
  {:else if $businessType.isError}
    something went wrong
  {:else if $isOnline}
    <button
      class="btn flex btn-ghost border-{text_color} border-opacity-20 bg-{text_color} bg-opacity-20 text-white"
      on:click={() => gotoPage(name.toLowerCase())}
    >
      <div class="flex-auto absolute left-1/2 -translate-x-1/2">Open card</div>
      <span class="flex-auto py-3" />
      <Icon type="ChevronRightIconOutline" />
    </button>
  {:else if !isBusinessPage && qrIsVisible}
    <button
      class="btn flex btn-ghost border-{text_color} border-opacity-20 bg-{text_color} border-none bg-opacity-20 text-white"
      on:click={() => (qrIsVisible = false)}
    >
      Close
    </button>
  {:else}
    <button
      class="btn flex btn-ghost border-{text_color} border-opacity-20 bg-{text_color} bg-opacity-20 text-white"
      on:click={() => (qrIsVisible = true)}
    >
      {#if $voucherExist.isLoading}
        loading
      {:else if $voucherExist.isError}
        something went wrong
      {:else if $voucherExist.data.length}
        Tap to redeem voucher
      {:else}
        <span class="absolute left-1/2 -translate-x-1/2">Tap to stamp</span>
        <span class="flex-auto" />
        <span class="animate-pulse">
          <Icon type="FingerPrintIconOutline" />
        </span>
      {/if}
    </button>
  {/if}
</div>
