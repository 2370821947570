<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import { v4 as uuidv4 } from "uuid";
  import { createEventDispatcher } from "svelte";
  import { fade, scale } from "svelte/transition";
  import Title from "$lib/components/Title.svelte";
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import { app_assets } from "$lib/constants";
  import { melon } from "$lib/stores/systemModals.store";
  import { supabase } from "$lib/db";
  import { navigate } from "svelte-routing/src/history";

  let isSignIn = false;
  let loading = false;
  let email = "";
  let password = "";
  let username = "";
  let isWithPassword = false;

  export let redirectTo = "/"; // Set the redirect URL here

  const dispatch = createEventDispatcher();

  const toggleMode = () => {
    isSignIn = !isSignIn;
  };

  $: if (!isWithPassword) {
    password = ''
  }
  const handleAuth = async () => {
    loading = true;
    if (isSignIn) {
      const { data: DataEmail, error: emailError } = await supabase
        .from("profiles")
        .select("*")
        .eq("email", email);

      if (DataEmail.length) {
        const signInOptions = isWithPassword ? { email, password } : { email };
        const { error } = await supabase.auth.signIn(signInOptions, {
          redirectTo,
        });

        if (error) {
          melon.alert({
            title: "Sign in error",
            message: error.message,
          });
        } else {
          melon.alert({
            title: "Sign in",
            message: isWithPassword
              ? "Signed in successfully"
              : "We've sent you a magic link, check your mail!",
          });
          isWithPassword ?? navigate("/");
        }
      } else {
        melon.alert({
          title: "Oops.. something went wrong",
          message: "No email associated with this account",
        });
      }
    } else {
      const uuid = uuidv4();
      const { data: DataEmail, error: emailError } = await supabase
        .from("profiles")
        .select("*")
        .eq("email", email);

      if (!DataEmail.length) {
        const signUpOptions = {
          email,
          password: uuid,
          data: { user_name: username.toLowerCase() },
        };
        const { error } = await supabase.auth.signUp(signUpOptions, {
          redirectTo,
        });

        if (error) {
          melon.alert({
            title: "Sign up error",
            message: error.message,
          });
        } else {
          melon.alert({
            title: "Sign up",
            message: "Check your email for the sign-up link!",
          });
        }
      } else {
        melon.alert({
          title: "Account already exists",
          message: "The user with this email or username already exists",
        });
      }
    }

    loading = false;
    dispatch("modalclose", { isSignIn });
  };
</script>

<div class="modal modal-open bg-opacity-0">
  <div
    role="button"
    on:keydown
    tabindex="0"
    class="absolute top-0 left-0 bg-base-200 bg-opacity-30 h-full w-full"
    out:fade|local={{ delay: 300 }}
    in:fade|local
    on:click={() => dispatch("modalclose")}
  />
    
  <div class="modal-box !max-w-sm relative" transition:scale>
    <form on:submit|preventDefault={handleAuth}>
      
      <div class="w-32 mx-auto py-7">
        <Image
          style="!object-contain"
          src={$isDarkMode
            ? `${app_assets}/melonfulldarkmode.png`
            : `${app_assets}/melonfull.png`}
        />
      </div>
      <button
        type="button"
        on:click={() => dispatch("modalclose")}
        class="btn btn-sm btn-circle absolute right-2 z-10 top-2"
      >
        ✕
      </button>
      <main class="grid grid-cols-1 gap-4">
        <Title padding={false}>
          {#if isSignIn}
            Sign In
          {:else}
            Sign Up
          {/if}
        </Title>

        <input
          class="input input-bordered"
          type="email"
          bind:value={email}
          placeholder="Email"
        />

        {#if isSignIn && isWithPassword}
          <input
            class="input input-bordered"
            type="password"
            bind:value={password}
            placeholder="Password"
          />
        {/if}
        {#if !isSignIn}
          <input
            class="input input-bordered"
            type="text"
            bind:value={username}
            placeholder="Username"
          />
        {/if}
        <button
          class="btn btn-primary"
          disabled={loading }
        >
          {isSignIn
            ? !loading
              ? "Sign In"
              : "Signing in.."
            : loading
              ? "Signing Up.."
              : "Sign up"}
        </button>

        {#if isSignIn}
          <p
        on:keydown
            class="text-center underline"
            on:click={() => (isWithPassword = !isWithPassword)}
          >
            Sign in with {!isWithPassword ? "password" : "magiclink"}
          </p>
          <div class="flex items-center gap-4 w-full">
            <hr class="flex-auto" />
            or
            <hr class="flex-auto" />
          </div>
        {/if}

        <p class="text-center">
          {isSignIn ? "Don't have an account?" : "Already have an account?"}
          <button type="button" on:click={toggleMode}>
            {isSignIn ? "Sign Up" : "Sign In"}
          </button>
        </p>
      </main>
    </form>
  </div>
</div>
