<script lang="ts">
	import Title from "$lib/components/Title.svelte";
	import { supabase } from "$lib/db";
    import { melon } from "$lib/stores/systemModals.store";
	import { onMount } from "svelte";
	import { getNotificationsContext } from "svelte-notifications";
	import { navigate } from "svelte-routing/src/history";

	const { addNotification } = getNotificationsContext();
	let password, confirmpassword, getAccesToken;

	onMount(() => {
		const urlParams = new URLSearchParams(window.location.search);

		getAccesToken = urlParams.get("access_token");
	});
	const triggerNotification = (isSucces, msg) => {
		addNotification({
			text: msg,
			position: "top-center",
			type: isSucces ? "success" : "error",
			removeAfter: 2000,
		});
	};
	const handleResetPassword = async () => {
		if (password == confirmpassword) {
			const { error, data } = await supabase.auth.update({
				password,
			});
			if (error)
				throw triggerNotification(
					false,
					"Something went wrong, please try again."
				);
			melon.alert({
				message: "Succesfully changed your password",
			});
			document.querySelector("form").reset();
			navigate("/");
		} else {
			triggerNotification(false, `passwords don't match`);
		}
	};
</script>

<div class="max-w-[920px] mx-auto container py-7 h-screen ">
	<div class="w-full max-w-md lg:mx-0 mx-auto">
		<Title>Set a new password</Title>
		<form
			on:submit|preventDefault={handleResetPassword}
			action=""
			class="mt-7 grid grid-cols-1 px-7 gap-4"
		>
			<div class="form-control ">
				<label for="newpassword" class="label"
					><span class="label-text">
						New password</span
					>
				</label>
				<input
					class="input input-bordered focus:outline-primary"
					bind:value={password}
					type="password"
					placeholder="New password"
				/>
			</div>
			<div class="form-control">
				<label for="repeatnewpassword" class="label"
					><span class="label-text"
						>Repeat new password</span
					></label
				>
				<input
					class="input input-bordered focus:outline-primary"
					bind:value={confirmpassword}
					type="password"
					placeholder="Confirm new password"
				/>
			</div>
			<div>
				<button class="btn btn-primary" type="submit" disabled={!password}
					>Save password</button
				>
			</div>
		</form>
	</div>
</div>
