<script lang="ts">
	import Pricing from "./Pricing.svelte";
	import Steps from "./Steps.svelte";
	import Header from "./Header.svelte";
	import Footer from "./Footer.svelte";
	import FAQ from "./FAQ.svelte";
	import Calculator from "./Features.svelte";
	import Contact from "./Contact.svelte";
	import Showcase from "./Showcase.svelte";
	import C2A from "./C2A.svelte";
</script>

<div class="flex w-full flex-col">
	<Header />
	<Showcase id="how-it-works"/>
	<Steps id="benefits"/>
	<C2A />
	<Calculator id="calculator" />
	<Pricing id="pricing"/>
	<FAQ  id="faq"/>
	<Contact id="contact"/>
	<Footer />
</div>
