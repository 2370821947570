<script lang="ts">
	import Appbar from "$lib/components/Appbar.svelte";
	import Image from "$lib/components/Image.svelte";
	import Title from "$lib/components/Title.svelte";
	import UploadAsset from "$lib/components/UploadAsset.svelte";
	import { supabase } from "$lib/db";
    import { melon } from "$lib/stores/systemModals.store";
	import { QueryClient, useQueryClient } from "@sveltestack/svelte-query";

	let updater = 1;
	const user = supabase.auth.user();
	let id = user.id;
	const bucket = `avatars/${id}/`;
	const path = "";
	const queryClient = useQueryClient();
	const src =
		"https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{id}/{id}.png";
	$: if (updater.toString()) {
		queryClient.invalidateQueries(["image", src]);
	}
</script>

<div class="max-w-[920px] mx-auto container py-7 h-screen ">
	<Title>Change your profile picture</Title>
	<div class="grid grid-cols-2 gap-4 px-7 w-full max-w-[400px]">
		{#key updater}
			<div class="pt-16">
				<div
					class=" rounded-xl  aspect-square flex items-center justify-center  w-full"
				>
					<Image
						style=" rounded-full "
						src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{id}/{id}.png?t={updater}"
					/>
				</div>
			</div>
		{/key}
		<div class="flex flex-col gap-3 pt-16">
			<UploadAsset
				on:uploadfinish={() => {
					melon.alert({message:"Profile picture updated. It might take a while before you see the updated picture in the app, be patient!"});
					updater++;
				}}
				backbutton={false}
				placeholderText="Select a profile picture"
				type="other"
				{bucket}
				{path}
				{id}
			/>
		</div>
	</div>
</div>
