<script lang="ts">
	import JSConfetti from "js-confetti";
	import { onDestroy, onMount } from "svelte";

	let canvas;
	let jsConfetti = new JSConfetti({ canvas });
	onMount(() => {
		jsConfetti = new JSConfetti({ canvas });
		startConfetti()
	});
	const startConfetti = () => {
		(async () => {
			for (let i = true; i == true; true) {
				await jsConfetti.addConfetti({
					// emojis: confetti,
					emojis: ["🍉"],
					// confettiRadius: 3,
					confettiNumber: 30,
				});
				jsConfetti.clearCanvas();
			}
		})();
	};

	onDestroy(() => {
		jsConfetti.clearCanvas();
	});
</script>

<canvas
	bind:this={canvas}
	class="z-0  pointer-events-none select-none confetti absolute top-0 left-0 h-full w-full"
/>
