<script lang="ts">
  import { useQuery } from "@sveltestack/svelte-query";
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import { fade } from "svelte/transition";
  import { app_assets } from "$lib/constants";

  export let src: string,
    darkSrc: string = "",
    contrast = "",
    id = "",
    nocache = false,
    style = "",
    spanstyle = "",
    banner = false,
    circle = false,
    alt = "",
    overlay = false,
    demoimage = false,
    showWhitePlaceholder = false,
    primary_color = "",
    demosrc = null;

  let img;
  let error = false;
  let success = false;
  let skipLoading = true;

  const imageUrlToBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function () {
          onSuccess(this.result);
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);
      }
    });
  };

  const getImage = async (url) => {
    try {
      const base64 = (await imageUrlToBase64(url)) as any;
      return base64;
    } catch (error) {
      return { data: null, error };
    }
  };

  const image = useQuery(["image", src], () => getImage(src));

  $: if (demosrc && demosrc.includes("data:image/")) {
  }
  $: if (success) {
    if (banner) {
      img.style = "background:{primary_color} !important;";
    }
  }
  $: if (error == true) {
    if (banner) {
      img.src = app_assets + "/empty.png";
      img.style.background = `${primary_color}`;
      img.classList.add("!object-contain");
    } else {
      img.src = "/icon.png";
      // img.src = "/icon.png";
    }
  }
  const imgtype = banner ? app_assets + "/empty.png" : "/icon.png";
  $: if ($image.isLoading) {
    skipLoading = true;
  } else {
    skipLoading = false;
  }
</script>

<!-- on:error={() => (error = true)} -->

{#if demoimage}
  <span class=" relative top-0 h-full w-full {spanstyle}">
    <img
      in:fade|local
      src={demosrc}
      style="background:{primary_color} !important;"
      alt=""
      class={` ${style} ${
        circle ? "rounded-full" : "rounded-xl"
      } h-full w-full object-cover`}
    />
    {#if overlay && error == false}
      <div
        class="{contrast != 'black'
          ? 'bg-black'
          : 'bg-white '} w-full h-full absolute top-0 bg-opacity-30"
      />
    {/if}
  </span>
{:else}
  <span class="block relative top-0 w-full h-full {spanstyle}">
    {#if $isDarkMode}
      {#if $image.isLoading}
        <img
          src={banner
            ? app_assets + "/empty.png"
            : app_assets + "/placeholder.png"}
          in:fade|local
          class={` ${style} ${
            circle ? "rounded-full" : "rounded-xl"
          } h-full mx-auto select-none pointer-events-none w-full object-cover`}
          style="background:{primary_color} !important;"
          {alt}
        />
      {:else if $image.isError}
        <img
          src={banner
            ? app_assets + "/empty.png"
            : app_assets + "/placeholder.png"}
          in:fade|local
          class={` ${style} ${
            circle ? "rounded-full" : "rounded-xl"
          } h-full mx-auto select-none pointer-events-none w-full object-cover`}
          style="background:{primary_color} !important;"
          {alt}
        />
      {:else}
        <img
          src={$image.data}
          in:fade|local={{ duration: skipLoading ? 0 : 200 }}
          on:error={(e) =>
            (e.target.src = banner
              ? app_assets + "/empty.png"
              : app_assets + "/placeholder.png")}
          class={` ${style} ${
            circle ? "rounded-full" : "rounded-xl"
          } h-full mx-auto select-none pointer-events-none w-full object-cover`}
          style="background:{primary_color} !important;"
          {alt}
        />
      {/if}

      {#if overlay && error == false}
        <div
          class="overlay {contrast != 'black'
            ? 'bg-black'
            : 'bg-white '} w-full h-full absolute top-0 left-0 bg-opacity-40"
        />
      {/if}
    {:else}
      <img
        src={$image.data}
        in:fade|local={{ duration: skipLoading ? 0 : 200 }}
        class={` ${style} ${
          circle ? "rounded-full" : "rounded-xl"
        } h-full mx-auto select-none pointer-events-none w-full object-cover`}
        style="background:{primary_color} !important;"
        {alt}
      />
      {#if overlay && error == false}
        <div
          class="overlay {contrast != 'black'
            ? 'bg-black'
            : 'bg-white '} w-full h-full absolute top-0 left-0 bg-opacity-40"
        />
      {/if}
    {/if}
  </span>
{/if}
