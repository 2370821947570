import Qrcode from "$lib/components/Qrcode.svelte";
import Menuitem from "$lib/components/Menuitem.svelte";
import Subscriptionplans from "$lib/features/landingpage/components/subscriptionplans.svelte";
import Signin from "$lib/features/auth/components/Signin.svelte";
import UploadAsset from "$lib/components/UploadAsset.svelte";

const components = {
  qrcode: Qrcode,
  uploadAsset: UploadAsset,
  menuitem: Menuitem,
  subscriptionplans: Subscriptionplans,
  signin: Signin,
};

export default components;
