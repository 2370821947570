// import type { FormTree } from "./types";
import formMethods from "../components/system/formMethods/index";

export default ({ business_id }) => {
  const form = {
    method: formMethods.createBeverage,
    business_id,
    fields: [
      {
        id: "p_name",
        name: "input",
        type: "text",
        required: true,
        label: "Name of the drink",
        placeholder: "Apple juice",
      },
      {
        name: "textarea",
        id: "p_description",
        type: "text",
        required: true,
        label: "A short description of the drink",
        placeholder: "Freshly juiced apples",
      },
      {
        id: "p_is_alcoholic",
        name: "switch",
        type: "checkbox",
        label: "Alcoholic drink",
      },
    ],
  };

  return form;
};
