<script lang="ts">
  import { fly } from "svelte/transition";
</script>

<div
  in:fly|local={{ y: 100, delay: 100, duration: 200 }}
  class="  business-content max-w-screen relative -mt-32 lg:bg-[##fcfcfc] shadow-gray-300 dark:shadow-black lg:shadow-2xl dark:bg-base-200 bg-[#fcfcfc] rounded-[40px] max-w-4xl container mx-auto top-0 w-full min-h-screen"
>
  <slot />
</div>
