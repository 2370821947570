<script lang="ts">
	import Icon from "$lib/components/Icon.svelte";
	export let isOpen = false;
	export let q: string;
	export let a: string;
</script>

<div class="w-full">
	<div class="block border-b border-gray-300" href="#">
		<div class="flex flex-wrap justify-between py-7 -m-1.5">
			<div class="flex-1 p-1.5">
				<div class="flex flex-wrap -m-1.5">
					<div class="text-primary w-auto p-1.5">
						<Icon
							type="QuestionMarkCircleIconOutline"
						/>
					</div>
					<div class="flex-1 p-1.5">
						<h3
							on:click={() =>
								(isOpen =
									!isOpen)}
							class="font-semibold leading-normal"
						>
							{q}
						</h3>
						{#if isOpen}
							<p
								class="mb-5 text-gray-600 font-medium leading-relaxed"
							>
								{a}
							</p>
						{/if}
					</div>
				</div>
			</div>
			<div
				on:click={() => (isOpen = !isOpen)}
				class="w-auto p-1.5"
			>
				<Icon
					type={isOpen
						? "MinusIconOutline"
						: "PlusIconOutline"}
				/>
			</div>
		</div>
	</div>
</div>
