import { supabase } from "../../../db";
import StampcardService from '../../stampcard/services/Stampcard.service'

export default class WalletService extends StampcardService {

  constructor() {
    super()
  }
  async getWallet(userid: string, { activeCards, selectedCategory = 'food' }) {
    const { data, error } = await supabase
      .from("cards")
      .select(
        `
          *,
          info:business_id(
            *, 
            type(*)
          )
      `
      )
      .eq("active", activeCards)
      .eq("info.type.name", selectedCategory)
      .order("active", { ascending: false })
      .order("created_at", { ascending: false })
      .eq("user_id", userid);

    if (error) throw error;

    return data;
  };
}
