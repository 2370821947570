<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import Image from "$lib/components/Image.svelte";
  import LoadingMelon from "$lib/components/LoadingMelon.svelte";
  import { goto } from "@roxi/routify";
  import ProfileService from "../services/Profile.service";
  import { useQuery } from "@sveltestack/svelte-query";
  import { supabase } from "$lib/db";

  const profileService = new ProfileService();

  export let sidebar = false;
  export let hideBackground = false;
  const profile = useQuery(["profile"], () => profileService.getProfile());
</script>

<div class="min-h-[400px] hidden" />
<header
  class="w-full {!sidebar
    ? 'min-h-[420px] '
    : ''} overflow-hidden grid items-center relative justify-center py-8"
>
  {#if !hideBackground && $profile.isSuccess}
    <div class="absolute w-full h-full">
      <Image
        style="rounded-none"
        overlay
        src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{$profile
          .data.id}/{$profile.data.id}.png"
      />
      <div class="backdrop-blur-lg w-full h-full absolute top-0 left-0" />
    </div>
  {/if}
  <div
    class=" {!hideBackground
      ? 'text-white'
      : 'text-black dark:!text-base-content'} text-white flex flex-col relative gap-5 justify-center items-center"
  >
    <div class="  rounded-full border-4 h-32 w-32">
      {#if $profile.isSuccess}
        <Image
          style="rounded-full "
          src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{$profile
            .data.id}/{$profile.data.id}.png"
        />
      {/if}
    </div>

    {#if $profile.isLoading}
      <h1 class="text-center text-xl mb-2 btn btn-ghost" />
    {:else if $profile.isError}
      <h1 class="text-center text-xl mb-2 btn btn-ghost">Error</h1>
    {:else}
      <h1 class="text-center text-xl gap-0 mb-2 btn btn-ghost">
        @<span class="capitalize">{$profile.data.username ? $profile.data.username : "no username yet"}</span>
      </h1>
    {/if}
  </div>
</header>
