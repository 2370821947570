import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core'

export const hapticsImpactMedium = async () => {
  if (!Capacitor.isNative) return
  await Haptics.impact({ style: ImpactStyle.Medium });
};

export const hapticsImpactLight = async () => {
  if (!Capacitor.isNative) return
  await Haptics.impact({ style: ImpactStyle.Light });
};

export const hapticsVibrate = async () => {
  if (!Capacitor.isNative) return
  await Haptics.vibrate();
};

// export const hapticsSelectionStart = async () => {
//   await Haptics.selectionStart();
// };

// export const hapticsSelectionChanged = async () => {
//   await Haptics.selectionChanged();
// };

// export const hapticsSelectionEnd = async () => {
//   await Haptics.selectionEnd();
// };
