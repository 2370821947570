<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import Question from "./Question.svelte";

export let id
  let showMore = false;
  const faq = [
    {
      q: "What is Melon Cards, and how does it work?",
      a: "Melon Cards is a loyalty program platform that helps businesses reward their customers for their repeated patronage. It works by allowing customers to earn stamps or points for each purchase, and when they reach a certain milestone, they get rewarded.",
    },
    {
      q: "How can I sign up for Melon Cards and get started?",
      a: "To sign up for Melon Cards and get started, visit our website, and choose a subscription plan that fits your business needs. Fill in your business details, set up your loyalty program, and you'll be good to go!",
    },
    {
      q: "What benefits does Melon Cards offer for businesses?",
      a: "Melon Cards offers several benefits for businesses, including increased customer retention, improved customer engagement and (coming soon) valuable data insights and the ability to run targeted marketing campaigns.",
    },
    {
      q: "Is Melon Cards suitable for small businesses as well?",
      a: "Absolutely! Melon Cards is designed to cater to businesses of all sizes, including small businesses. It's an excellent way to build customer loyalty and grow your business.",
    },
    {
      q: "Can I customize the loyalty program to suit my business needs?",
      a: "Absolutely! Melon Cards provides a wide range of customization options. You can change the amount of stamps needed for a reward, decide what the actual reward will be, and even customize your logo, color scheme, and banner to match your brand identity. Tailor it to align perfectly with your unique business requirements.",
    },
    {
      q: "How do customers earn stamps and rewards with Melon Cards?",
      a: "Customers earn stamps or points with each eligible purchase. Once they accumulate enough stamps or points, they can redeem rewards or enjoy discounts on future purchases.",
    },
    {
      q: "Is there a mobile app for customers to track their loyalty progress?",
      a: "Yes, we have a user-friendly mobile app for customers. They can easily track their loyalty progress, view rewards, and stay updated on your latest offers.",
    },
    {
      q: "What types of rewards can I offer to my loyal customers?",
      a: "You have the flexibility to offer various rewards, such as discounts, free items, special access, or exclusive perks. It's all up to you and what best suits your business.",
    },
    {
      q: "How can I promote my loyalty program to customers effectively?",
      a: "We provide marketing materials and tips to help you promote your loyalty program. Additionally, through the app (settings > business > marketing), you can request marketing materials like posters and flyers to use in your in-store displays or as part of your email campaigns. Social media is another great channel to spread the word and engage your customers.",
    },
    // {
      // q: "Can I integrate Melon Cards with my existing POS system?",
      // a: "Yes, Melon Cards can be seamlessly integrated with most POS systems. Our technical support team will guide you through the process to ensure a smooth setup.",
    // },
    // {
      // q: "Is there any customer support available if I have questions?",
      // a: "Absolutely! Our customer support team is available to assist you with any questions or concerns you may have. Feel free to reach out to us via email or phone.",
    // },
    {
      q: "How secure is the customer data collected through Melon Cards?",
      a: "We take data security seriously. Customer data collected through Melon Cards is encrypted and stored securely, following industry standards and best practices to protect your customers' information.",
    },
    {
      q: "What is the pricing structure for using Melon Cards?",
      a: "Check out our pricing table for more information."
    },
    // {
      // q: "Can I run multiple loyalty programs for different customer segments?",
      // a: "Absolutely! With Melon Cards, you can create and manage multiple loyalty programs, each tailored for different customer segments or locations.",
    // },
    {
      q: "How can I track the effectiveness of my loyalty program using Melon Cards?",
      a: "Coming soon: Melon Cards provides comprehensive analytics and reporting tools. You can track customer engagement, the effectiveness of rewards, and other key metrics to measure the success of your loyalty program.",
    },
  ];
</script>

<a {id}>
  <section class="py-32 overflow-hidden">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -m-8">
        <div class="w-full md:w-1/2 p-8">
          <div class="md:max-w-md">
            <h2
              class="mb-7 text-5xl md:text-6xl font-black font-heading tracking-px-n leading-tight"
            >
              Frequently Asked Questions
            </h2>
            <p class="mb-11 text-gray-600 font-medium leading-relaxed">
              Your Melon Cards Guide! Curious about our loyalty card
              platform? We've got you covered! Check out our FAQ to find all
              the juicy details! Have more questions? Shoot us a message via
              our contact form. Let's make your business thrive with loyalty!
            </p>
            <div class="md:inline-block">
              <button
                on:click={() => (showMore = !showMore)}
                class="btn btn-primary "
              >
                Read Our Full FAQs
                <Icon
                  type={showMore
                    ? "ChevronUpIconOutline"
                    : "ChevronDownIconOutline"}
                /></button
              >
            </div>
          </div>
        </div>
  
        <div class="w-full md:w-1/2 p-8">
          <div class="md:max-w-2xl ml-auto">
            <div class="flex flex-wrap">
              {#each faq.slice(0, showMore ? faq.length - 1 : 3) as { q, a }}
                <Question {a} {q} />
              {/each}
              <div class="my-4 w-full flex justify-center  " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</a>
