import { supabase } from "../../../db";
import { melon } from "../../../stores/systemModals.store";

export default async (
  { p_name, p_price, p_description, p_is_alcoholic = false, p_business_id },
  cb
) => {
  const { data, error } = await supabase.rpc("create_beverage", {
    p_name,
    p_is_alcoholic,
    p_business_id,
    p_price,
    p_description,
  });

  if (error) {
    melon.alert({
      title: "Error",
      message: `Something went wrong, ${error.message}`,
    });

    throw error;
  }

  cb();
};
