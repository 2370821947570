<script lang="ts">
	import Appbar from "$lib/components/Appbar.svelte";
	import type { Icon as IIcon } from "src/utils/icontypes";
	import Icon from "$lib/components/Icon.svelte";
	import Image from "$lib/components/Image.svelte";
	import Loading from "$lib/components/Loading.svelte";
	import Title from "$lib/components/Title.svelte";
	import { supabase } from "$lib/db";
	import { businessCheck } from "src/utils/businessCheck";
	import { onMount } from "svelte";
	import { Link, link, navigate } from "svelte-routing";
	import { user } from "$lib/stores/auth.store";

	let info;
	let isBusinessOwner = false;
	const signout = () => {
		supabase.auth.signOut();
	};
	$: if ($user) {
		(async () => (isBusinessOwner = await businessCheck()))();
	}

	const getBusinesses = async () => {
		const { data, error } = await supabase
			.from("business_owners")
			.select(
				`
          *,
          business:business_id(*)
        `
			)
			.eq("user_id", $user.id);
		if (error) throw error;
		return data;
	};

	interface ISettingpage {
		name: string;
		link: string;
		icon: IIcon;
	}

	const settingpages: ISettingpage[] = [
		{
			name: "Change username",
			link: "/profile/settings/username",
			icon: "UserIconOutline",
		},
		{
			name: "Change profile picture",
			link: "/profile/settings/profilepicture",
			icon: "PhotographIconOutline",
		},
		{
			name: "Change e-mail",
			link: "/profile/settings/email",
			icon: "MailIconOutline",
		},
		{
			name: "Change password",
			link: "/profile/settings/password/newpassword",
			icon: "ShieldCheckIconOutline",
		},
	];
	onMount(async () => {
		// info = await App.getInfo();
	});
</script>

{#if !$user}
	<div class="h-screen w-screen flex items-center justify-center">
		<Loading />
		{navigate("/signin")}
	</div>
{:else}
	<div class="max-w-[920px] mx-auto container !pb-20 py-7">
		<Title>Menu <span class="flex-auto" /></Title>
		<div
			class="grid grid-cols-1 flex-col px-7  max-w-md pt-7 space-y-7"
		>
			<div class="space-y-12">
				<div class="space-y-4">
					<p class="font-semibold">Privacy</p>
					<ul
						class=" bg-opacity-50 menu bg-base-200 p-2 rounded-box"
					>
						{#each settingpages as page}
							<li>
								<Link
									to={page.link}
								>
									<Icon
										type={page.icon}
									/>
									{page.name}
								</Link>
							</li>
						{/each}
						<li>
							<div
								on:keydown
								on:click={() =>
									signout()}
							>
								<Icon
									type="LogoutIconOutline"
								/>

								Logout
							</div>
						</li>
					</ul>
				</div>
				<div class="space-y-4">
					<p class="font-semibold">Business</p>
					<ul
						class=" bg-opacity-50 menu bg-base-200 p-2 rounded-box"
					>
						{#if isBusinessOwner}
							<li use:link>
								<a>
									<Icon
										type="ChartSquareBarIconOutline"
									/>
									Go to dashboard (coming soon)
								</a>
							</li>
						{/if}
						<button
							class="btn btn-primary mt-3"
							on:click={() =>
								navigate(
									"/business/new"
								)}
						>
							<Icon
								type="PlusIconOutline"
							/>
							Create a new business
						</button>
						<li class="mt-4">
							{#await getBusinesses() then businesses}
								<details>
									<summary
									>
										My
										businesses
									</summary>
									<ul>
										{#each businesses.filter(({ business }) => business.active == true) as { business }}
											<li
												class="flex flex-row items-center  "
											>
												<div
													on:keydown
													class="w-full !bg-none"
													on:click={() =>
														navigate(
															"/page/" +
																business.name
														)}
												>
													<div
													>
														<Image
															alt=""
															primary_color={business.primary_color}
															style=" !aspect-square bg-red-500 p-2 !object-contain																																																																!m-0 rounded-xl w-12"
															src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{business.id}/logo/{business.id}.png"
														/>
													</div>
													<div
														class="focus:bg-none	flex-auto p-0"
													>
														{business.name}
													</div>
												</div>
											</li>
										{/each}
									</ul>
								</details>
							{/await}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="max-w-xs px-10 mt-6 py-4">
			Version:
			{APP_VERSION}
		</div>
	</div>
{/if}
