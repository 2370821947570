import { supabase } from "../../../db";
import { melon } from '../../../stores/systemModals.store'
import {baseURL} from '../../../../lib/constants'
export default class CardService {

  async getBusinessType(businessName: string) {
    const { data, error } = await supabase
      .from("businesses")
      .select("type(name)")
      .eq("name", businessName.toLowerCase())
      .single();
    if (error) throw "";
    return data.type.name;
  };

  showBusinessQrcode({ business }) {
    melon.alert({
      title: business.name,
      message: "Share this business with your friends",

      widgetTree: [
        {
          name: "qrcode",
          manual: true,
          src: `${baseURL}/page/${business.name.replace(
            " ",
            "%20"
          )}`,
        },
      ],
    });
  };

  showCardOptions({ business, widgetTree }) {
    melon.menu({
      title: business.name,
      message: "Card settings",
      widgetTree
    });
  };

}
