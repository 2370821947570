<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import { onDestroy } from "svelte";
  import LoadingMelon from "./LoadingMelon.svelte";

  let showLoading = true;
  onDestroy(() => {
    showLoading = false;
  });
</script>

<!-- <Image style="custom_outline h-16 !object-contain" src="/loading.svg" /> -->
<div class="w-full h-full flex items-center justify-center">
  <span class="loading loading-spinner loading-md" />
  <!-- <LoadingMelon /> -->
</div>
