<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import Image from "$lib/components/Image.svelte";
  import { app_assets } from "$lib/constants";
  import { onDestroy, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  export let id = "";
  let showMenu = false;
  const menu = [
    {
      title: "How it works",
      link: "#how-it-works",
    },
    {
      title: "Benefits",
      link: "#benefits",
    },
    {
      title: "Melon Calculator",
      link: "#calculator",
    },
    {
      title: "Pricing",
      link: "#pricing",
    },
    {
      title: "FAQ",
      link: "#faq",
    },
    {
      title: "Contact",
      link: "#contact",
    },
  ];

  $: if (showMenu.toString()) {
    console.log("change");
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        anchor.addEventListener("click", () => (showMenu = false));
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }
  onMount(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        anchor.addEventListener("click", () => (showMenu = false));
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });

  onDestroy(() => {
    document.querySelectorAll("a").forEach((a) => {
      // a.removeEventListener("click", () => (showMenu = false), true);
    });
  });
</script>

<div class="w-full bg-base-100 z-20 sticky top-0">
  <div class="   container mx-auto overflow-hidden">
    <div class="flex items-center justify-between px-4 py-5">
      <div class="w-auto mx-auto pt-4 md:m-0">
        <div class="flex flex-wrap items-center">
          <div class="w-auto md:mr-14 transform md:translate-0 translate-x-8">
            <a href="#">
              <Image
                style="rounded-none !object-contain w-24"
                src="{app_assets}/melonfull.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="w-auto">
        <div class="flex flex-wrap items-center">
          <div class="w-auto hidden lg:block">
            <ul class="flex items-center mr-16">
              {#each menu as item}
                <li class="mr-9 font-medium hover:text-gray-700">
                  <a href={item.link}>{item.title}</a>
                </li>
              {/each}
            </ul>
          </div>
          <div class="w-auto hidden lg:block">
            <div class="inline-block hidden">
              <!-- <a href="#pricing"> -->
              <button class="btn btn-primary">Sign in</button>
              <!-- </a> -->
            </div>
          </div>
          <div class="w-auto lg:hidden">
            <div>
              <button
                on:click={() => (showMenu = !showMenu)}
                class="btn-primary btn !rounded-full btn-square"
              >
                <Icon type="MenuIconOutline" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {#if showMenu} -->
    <div
      class=" {showMenu
        ? ''
        : 'hidden'} navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50"
    >
      <div
        in:fade|local
        out:fade|local={{ delay: 300 }}
        on:click={() => (showMenu = false)}
        class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"
      />
      <nav
        in:fly|local={{ x: -100, delay: 300 }}
        out:fly|local={{ x: -100, delay: 0 }}
        class="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto"
      >
        <div class="flex flex-wrap justify-between h-full">
          <div class="w-full">
            <div class="flex items-center justify-between -m-2">
              <div class="w-auto p-2" />
              <div class="w-auto p-2">
                <button
                  on:click={() => (showMenu = !showMenu)}
                  class="btn btn-ghost !rounded-full btn-square"
                >
                  <Icon type="XIconOutline" />
                </button>
              </div>
            </div>
          </div>
          <div class="max-w-[200px] mx-auto w-full">
            <Image style="!object-contain" src="{app_assets}/melonfull.png" />
          </div>
          <div class="flex flex-col justify-center pb-16 w-full">
            <ul>
              {#each menu as item}
                <li class="mb-12">
                  <a class="font-medium hover:text-gray-700" href={item.link}
                    >{item.title}</a
                  >
                </li>
              {/each}
            </ul>
          </div>
          <div class="flex flex-col justify-end w-full pb-8">
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="block">
                  <a href="#pricing">
                    <button class="btn btn-primary" type="button"
                      >Join now</button
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- {/if} -->
  </div>
</div>
<a {id}>
  <section class="">
    <div class="overflow-hidden pt-16">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -m-8">
          <div class="w-full md:w-1/2 p-8">
            <div
              class="inline-block mb-6 px-2 py-1 font-semibold hidden bg-green-100 rounded-full"
            >
              <div class="flex flex-wrap items-center -m-1">
                <div class="w-auto p-1">
                  <a class="text-sm" href=""
                    >👋 We are hiring! View open roles</a
                  >
                </div>
                <div class="w-auto p-1">
                  <Icon type="MenuIconOutline" />
                </div>
              </div>
            </div>
            <h1
              class="mb-6 text-3xl md:text-4xl lg:text-6xl font-black font-heading md:max-w-xl leading-none"
            >
              Boost your business with our loyalty-platform!
            </h1>

            <p class="mb-11 text-lg text-gray-900 font-medium md:max-w-md">
              Reward your loyal customers and encourage repeat visits. Expand
              your customer base and generate more revenue.
            </p>
            <div class="flex flex-wrap -m-2.5 mb-20">
              <div class="w-full md:w-auto p-2.5">
                <div class="">
                  <a href="#pricing">
                    <button class="btn btn-primary w-full">Join now</button>
                  </a>
                </div>
              </div>
              <div class="w-full md:w-auto p-2.5">
                <div class="block">
                  <a href="#how-it-works">
                    <button class="btn btn-outline w-full">
                      <div
                        class="flex flex-wrap justify-center items-center -m-1"
                      >
                        <div class="w-auto p-1">
                          <span>Read more</span>
                        </div>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 p-8">
            <Image
              style="transform  hover:-translate-y-16 transition ease-in-out duration-1000"
              src="{app_assets}/images/header_card.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</a>
