<script lang="ts">
  import {
    useQuery,
    useQueryClient,
  } from "@sveltestack/svelte-query";
  import { createEventDispatcher, onDestroy } from "svelte";
  import Stampgrid from "./Stampgrid.svelte";
  import StampcardService from "../services/Stampcard.service";
  import Cardcontainer from "$lib/features/card/components/Cardcontainer.svelte";
  import StampcardC2A from "./StampcardC2A.svelte";
  import Stampcardconfetti from "./Stampcardconfetti.svelte";
  import Stampcardheader from "./Stampcardheader.svelte";
  import { melon } from "$lib/stores/systemModals.store";
  import BusinessService from "$lib/features/business/services/Business.service";

  export let business;
  export let demoimagesrc = null;
  export let cardData = { id: "", stamps: 0 };
  export let active = true;
  export let democonfetti = false;
  export let demo = false;
  export let creatormode = false;
  export let isBusinessPage = false;
  export let qrIsVisible = false;
  export let params = {};

  const businessService = new BusinessService()
  const stampcardService = new StampcardService();
  const {
    name,
    primary_color,
    max_stamps,
    confetti,
    text_color = "white",
  } = business;

  let { stamps } = cardData;
  let updated = 0;
  let voucherExist;

  const queryClient = useQueryClient();
  const dispatch = createEventDispatcher();

  onDestroy(() => {
    voucher.unsubscribe();
    cards.unsubscribe();
  });

  const cards = stampcardService.cardSubscription(cardData.id, (s: number) => {
    //TODO
    stamps = s;
    qrIsVisible = false;
    if (stamps && stamps <= max_stamps) {
      melon.alert({
        message:
          "Congratulations! You've just earned a stamp towards your rewards! Keep collecting stamps to unlock exciting benefits and exclusive offers. ✨",
        title: "Awesome! You just received a stamp!",
      });
    }
  });

  const voucher = stampcardService.voucherSubscription(
    cardData.id,
    (u: number) => {
      qrIsVisible = false;
      updated = u;
    }
  );

  $: if (demo == false && business) {
    voucherExist = useQuery(["voucher", business.id + updated], () =>
      stampcardService.checkVoucherExist(business.id)
    );
  }

  const businessType = useQuery(["businesstype", business.id], () =>
    stampcardService.getBusinessType(business.name)
  );
  const businesscache = useQuery(
    ["businessName", business.id],
    () => businessService.getBusinessByName(business.id),
    {
      retry: 1, // Will retry failed requests 10 times before displaying an error
    }
  );
  const stampcard = useQuery(
      ["stampcard", business.id],
      () => businessService.getStampcard(business.id),
      {
        retry: 1, // Will retry failed requests 10 times before displaying an error
      }
    );
</script>

<!-- {element} -->
<Cardcontainer
  let:collapse
  {demo}
  {isBusinessPage}
  {primary_color}
  {active}
  {business}
  {qrIsVisible}
>
  <Stampcardheader
    {demoimagesrc}
    {creatormode}
    cardId={cardData.id}
    on:archivecard={() => dispatch("archivecard")}
    on:toggleActive={(e) => (active = e.detail)}
    on:delete={(e) => dispatch("delete", e.detail)}
    bind:active
    {business}
    {name}
    {params}
    {text_color}
    {businessType}
  />
  <StampcardC2A
    {isBusinessPage}
    {text_color}
    bind:qrIsVisible
    bind:voucherExist
    {demo}
    {businessType}
    {name}
  />
  <div class="min-h-[12px] flex-auto" />
  <div
    class="flex aspect-square {!collapse
      ? 'max-h-[280px]  overflow-hidden'
      : 'w-full'} "
  >
    <div class="w-full">
      {#key stamps}
        <Stampgrid
          {stamps}
          {voucherExist}
          {business}
          bind:qrIsVisible
          {businessType}
          {cardData}
          {demo}
          {text_color}
          {creatormode}
          {demoimagesrc}
        />
      {/key}
    </div>
  </div>
  {#if stamps == 0}
    {#if $voucherExist && $voucherExist.isSuccess}
      {#if $voucherExist.data.length}
        <Stampcardconfetti
          {qrIsVisible}
          {isBusinessPage}
          {democonfetti}
          bind:voucherExist
          {demo}
          {confetti}
        />
      {/if}
    {/if}
  {/if}
</Cardcontainer>