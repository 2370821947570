<script>
  import { createEventDispatcher } from "svelte";

  export let label = "";
  export let props;
  const dispatch = createEventDispatcher();
  const dispatchInput = (e) => {
    dispatch("input", e.target.value);
  };
</script>

<div class="form-control w-full">
  <label class="label gap-3 grid grid-cols-1">
    <span class="label-text">{@html label}</span>
    <input
      on:input={(e) => dispatchInput(e)}
      {...props}
      class="input input-bordered font-normal w-full"
    />
  </label>
</div>
