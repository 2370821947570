<script lang="ts">
	import Image from "$lib/components/Image.svelte";
	import { app_assets } from "$lib/constants";
</script>

<section
	class="relative py-28 overflow-hidden bg-gradient-to-r from-primary to-[#09322D]"
>
	<!-- <img class="absolute bottom-0 right-0" src="flaro-assets/images/cta/gradient.svg" alt="" /> -->
	<div class="relative z-10 container px-4 mx-auto">
		<div class="flex flex-wrap items-center -m-8">
			<div class="w-full md:w-1/2 p-8">
				<h2
					class="mb-5 text-6xl md:text-7xl text-white font-bold tracking-px-n leading-tight"
				>
					Join Melon Cards today
				</h2>
				<p
					class="text-white text-opacity-90 font-medium md:max-w-xl mb-6"
				>
					From cozy coffee shops and mouthwatering
					food joints to trendy fashion boutiques
					and fitness studios, our versatile
					loyalty card platform is perfect for any
					industry. Reward your valued customers,
					build lasting connections, and watch
					your business soar to new heights!
				</p>
				<a href="#pricing">
					<button
						class="btn btn-outline border-white text-white"
						>Join now</button
					>
				</a>
			</div>
			<div class="w-full md:w-1/2 p-8">
				<div class=" ">
					<Image
						src="{app_assets}/images/personas.png"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</section>
