<script lang="ts">
	import { createEventDispatcher, onMount } from "svelte";

	export let title = "Alert";
	export let message: string = "";
	export let type = "";
	export let yes;
	export let no;

	let el;
	const dispatch = createEventDispatcher();
	onMount(() => {
		el.showModal();
	});
	const handleButtonClick = (answer) => {
		dispatch("remove");

		if (answer) {
			yes();
		} else {
			no();
		}
		// dispatch("answer", answer);
		el.close();
	};
</script>

<!-- Open the modal using ID.showModal() method -->
<dialog bind:this={el} class="modal">
	<div
		on:keydown
		class="h-full w-full absolute top-0 left-0"
		on:click={handleButtonClick}
	/>
	<form method="dialog" class="max-w-sm modal-box">
		<h3 class="font-bold text-lg">{title}</h3>
		<p class="py-4">
			{message}
		</p>
		<div class="modal-action grid grid-cols-2">
			<button
				on:click={() => handleButtonClick(false)}
				class="btn btn-outline">Cancel</button
			>
			<button
				on:click={() => handleButtonClick(true)}
				class="btn btn-primary">Okay
				</button
			>
		</div>
	</form>
</dialog>
