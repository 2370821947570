<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import { melon } from "$lib/stores/systemModals.store";
  import { onMount } from "svelte";
  export let text_color: string;
  export let index: number;
  export let stamps: number;
  export let creatormode: boolean;
  export let demoimagesrc: string;
  export let business: {
    id: number;
    name: string;
    max_stamps: number;
    [key: string]: any;
  };
  // let el;
  onMount(() => {
    // melon.alert({message: JSON.stringify(el.style.height)});
    // el.style.maxHeight = el.offsetWidth - 10 + 'px'
  });
  // el.style.height = '40px';
</script>

<div
  class={`stamp !text-${text_color} object-contain ${
    index <= stamps - 1 ? "bg-opacity-30" : "bg-opacity-20 opacity-40"
  } rounded-xl flex items-center justify-center  bg-${text_color} col-span-1 row-span-1 aspect-square `}
>
  <div class="w-16 h-16">
    <Image
      demoimage={creatormode}
      demosrc={demoimagesrc}
      style="!aspect-square rounded-none {business.max_stamps <= 1
        ? 'w-full max-w-[90%] bg-purple-500'
        : 'w-10'}  !object-contain"
      src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{business.id}/logo/{business.id}.png"
    />
  </div>
</div>
