<script lang="ts">
  import Qrcode from "$lib/components/Qrcode.svelte";
  import { supabase } from "$lib/db";
  import { isOnline } from "$lib/stores/isOnline.store";
  import Stamp from "./Stamp.svelte";

  export let demo,
    business,
    qrIsVisible,
    businessType,
    cardData,
    text_color,
    voucherExist,
    stamps,
    creatormode,
    demoimagesrc;

  const getStamps = async () => {
    const { data, error } = await supabase
      .from("cards")
      .select("stamps")
      .eq("id", cardData.id)
      .single();

    if (error) throw error;
    return data.stamps;
  };
</script>

<div
  style=""
  class="stampgrid w-full gap-3 {business.max_stamps <= 1 ? '!aspect-square !grid-cols-1' : ''}  {business.max_stamps <= 9 ? '!aspect-square' : ''} {demo
    ? '_3d '
    : ''} stamps grid z-10 relative {business.max_stamps <= 10
    ? 'grid-cols-3 '
    : 'grid-cols-3 gap-3 '}

  {qrIsVisible ? 'qrvisible grid-rows-1' : ' '}
  "
>
  {#if qrIsVisible}
    <span class="col-span-3">
      <Qrcode
        id={"business-" + business.id}
        src={`/page/${business.id}/addstamp/${cardData.id}`}
        color={text_color}
      />
    </span>
  {:else if $voucherExist?.isLoading}
    loading
  {:else if $voucherExist?.isError}
    something went wrong
  {:else if $voucherExist?.data.length}
    {#if business}
      {#if $isOnline}
        {#await getStamps() then stamps}
          {#key stamps}
            {#each Array(business.max_stamps) as _, index}
              <Stamp
                bind:stamps={business.max_stamps}
                {index}
                {text_color}
                {business}
                {creatormode}
                {demoimagesrc}
              />
            {/each}
          {/key}
        {/await}
      {:else}
        {#key stamps}
          {#each Array(business.max_stamps) as _, index}
            <Stamp
              bind:stamps={business.max_stamps}
              {index}
              {text_color}
              {business}
              {creatormode}
              {demoimagesrc}
            />
          {/each}
        {/key}
      {/if}
    {/if}
  {:else if $isOnline}
    {#await getStamps() then stamps}
      {#key stamps}
        {#each Array(business.max_stamps) as _, index}
          <Stamp
            {stamps}
            {index}
            {text_color}
            {business}
            {creatormode}
            {demoimagesrc}
          />
        {/each}
      {/key}
    {/await}
  {:else}
    {#key stamps}
      {#each Array(business.max_stamps) as _, index}
        <Stamp
          {stamps}
          {index}
          {text_color}
          {business}
          {creatormode}
          {demoimagesrc}
        />
      {/each}
    {/key}
  {/if}
</div>

<style>
  .stamps {
    transition: all 0.4s ease;
  }
</style>
