<script lang="ts">
  import Title from "$lib/components/Title.svelte";
  import Image from "$lib/components/Image.svelte";
  import { supabase } from "$lib/db";
  import { useQuery } from "@sveltestack/svelte-query";
  import { Link, navigate } from "svelte-routing";
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import { app_assets } from "$lib/constants";
  import Appbar from "$lib/components/Appbar.svelte";

  export let redirectTo = "";

  let signupdone = false,
    loading = false,
    message,
    email = "",
    password = "",
    confirmpassword = "",
    username = "",
    selectedCountry = "";

  $: if (signupdone) {
    navigate("/signin", { replace: true });
  }

  const getCountries = async () => {
    const { data, error } = await supabase.from("countries").select("*");
    if (error) message = { success: false, display: error.message };
    if (error) throw error;
    return data;
    // countries = data;
  };

  const handleSignup = async () => {
    if (password != confirmpassword) {
      message = {
        success: false,
        display: "Password and Confirm Password fields do not match",
      };
      return;
    }

    loading = true;
    const { error } = await supabase.auth.signUp(
      { email, password },
      {
        redirectTo,
        data: {
          user_name: username.toLowerCase(),
        },
      }
    );

    if (error) {
      alert(error.message);
    } else {
      alert("We have sent you an confirmation email. Please check your email");
      signupdone = true;
    }

    loading = false;
  };
  const countries = useQuery("countries", getCountries);
</script>

<Appbar backbutton />
<div class="w-32 mx-auto pb-7 pt-safe">
  {#if $isDarkMode}
    <Image style="!object-contain" src="{app_assets}/melonfulldarkmode.png" />
  {:else}
    <Image style="!object-contain" src="{app_assets}/melonfull.png" />
  {/if}
</div>
<form
  on:submit|preventDefault={handleSignup}
  class="container max-w-sm space-y-7 mx-auto py-32 lg:mt-4 !px-7"
>
  <div class=" prose lg:prose-lg lg:text-left">
    <Title padding={false}>Sign up</Title>
  </div>
  <div class="form-control">
    <label for="" class="label"><span class="label-text">Username</span></label>
    <input
      class="input input-bordered"
      type="text"
      placeholder="johndoe"
      name="username"
      bind:value={username}
    />
  </div>
  <div class="form-control">
    <label for="email" class="label">
      <span class="label-text">Email address</span>
    </label>
    <input
      class="input input-bordered focus:outline-primary"
      id="email"
      placeholder="john@doe.com"
      type="email"
      bind:value={email}
    />
  </div>

  <div class="form-control">
    <label for="password" class="label">
      <span class="label-text">Password</span>
    </label>
    <input
      class="input input-bordered focus:outline-primary"
      id="password"
			placeholder="password"
			type="password"
			bind:value={password}
		/>
	</div>

	<div class="form-control">
		<label for="confirmpassword" class="label">
			<span class="label-text">Confirm Password</span>
		</label>
		<input
			class="input input-bordered focus:outline-primary"
			id="confirmpassword"
			placeholder="password"
			type="password"
			bind:value={confirmpassword}
		/>
	</div>
	<div class="flex-row flex w-full gap-4  mt-7">
		<div>
			<input
				class="checkbox"
				type="checkbox"
				checked={false}
			/>
		</div>
		<div class="">
			I agree to the <span class="font-semibold underline"
				>terms and conditions</span
			> of Sonami
		</div>
	</div>
	<p class="">
		Already have an account? <Link to="/signin" class="font-bold"
			>Sign in here</Link
		>
	</p>
	<div class="flex sticky bottom-10">
		<button
			type="submit"
			class="flex-auto btn  btn-primary mt-3 mb-4"
			disabled={loading}
		>
			{loading ? "Loading" : "Sign up"}
		</button>
	</div>
	<div />
</form>
