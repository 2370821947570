<script context="module">
  export const load = () => {
    return supabase.auth.user() ? {} : { redirect: "/signin" };
  };
</script>

<script lang="ts">
  import Voucher from "$lib/components/Voucher.svelte";
  import { supabase } from "$lib/db";
  import { useQuery } from "@sveltestack/svelte-query";
  import Icon from "$lib/components/Icon.svelte";
  import { navigate } from "svelte-routing/src/history";

  const user = supabase.auth.user();

  const getVouchers = async () => {
    const { data, error } = await supabase
      .from("vouchers")
      .select(`*, business:business_id(*, type(*))`)
      .eq("valid", true)
      .eq("user_id", user.id);
    if (error) throw error;
    return data;
  };

  const vouchers = useQuery("vouchers", getVouchers);
  const placeholderVoucher = {
    business: {
      primary_color: "#fdfdfd",
      voucher_description: "",
    },
  };
</script>

<div
  class="bg-base-100 rounded-t-3xl lg:mt-0 relative space-y-7 flex flex-col container mx-auto flex-auto"
>
  <h1 class=" h-8 w-full flex items-center px-7 text-xl font-semibold">
    Vouchers <span class="flex-auto" />
    <Icon type="TicketIconOutline" />
  </h1>
  <div class=" px-7">
    <ul class="max-w-[400px] flex flex-col gap-4">
      {#if $vouchers.isLoading}
        {#each Array(5) as _}
          <Voucher placeholder voucher={placeholderVoucher} />
        {/each}
      {:else if $vouchers.isError}
        something went wrong
      {:else if $vouchers.data.length}
        {#each $vouchers.data as voucher}
          <span
            class="animate-wigglequick"
            on:keyup
            on:click={() => navigate(`/page/${voucher.business.name}`)}
          >
            <Voucher {voucher} />
          </span>
        {/each}
      {:else}
        <div class=" alert justify-center">No vouchers saved up yet</div>
      {/if}
    </ul>
  </div>
</div>
