<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import type { Icon as IIcon } from "src/utils/icontypes";
  import Image from "$lib/components/Image.svelte";
  import StampcardService from "$lib/features/stampcard/services/Stampcard.service";
  import { useQuery } from "@sveltestack/svelte-query";
  import { checkIsOwner } from "src/utils/isOwner";
  import { createEventDispatcher } from "svelte";
  import { supabase } from "$lib/db";
  import { melon } from "$lib/stores/systemModals.store";

  export let params;
  export let demoimagesrc;
  export let creatormode;
  export let business;
  export let name;
  export let active;
  export let cardId;
  export let text_color;
  export let businessType;

  let stampcardService = new StampcardService();

  const dispatch = createEventDispatcher();

  const isOwner = useQuery(["checkIsOwner", business.id], () =>
    checkIsOwner(business.id)
  );

  interface widget {
    icon: IIcon;
    [key: string]: any;
  }

  const toggleCard = async (id) => {
    console.log(id);
    dispatch("archivecard");
    const { data, error } = await supabase.rpc("toggle_card", {
      businessid: id,
    });
    if (error) return;

    dispatch("toggleActive", data[0].active);
  };

  const deleteCard = async () => {
    const { data, error } = await supabase.rpc("delete_card", {
      p_card_id: cardId,
    });

    if (error) return melon.alert({ title: "Error", message: error.message });

    dispatch("delete", cardId);
    melon.alert({
      widgetTree: [],
      message: "succesfully deleted card",
      title: "Card deleted",
    });
  };

  const openDeleteCardModal = () => {
    melon.confirm({
      title: business.name,
      message: "Are you sure you want to delete this card?",
      yes: () => deleteCard(),
      no: () => {},
    });
  };

  $: widgetTree = [
    //  {
    //    name: "menuitem",
    //    visible: $isOwner.data ?? $isOwner.data,
    //    description: "Customise card",
    //    icon: "PencilIconOutline",
    //    action: () =>
    //      (window.location.href = `/dashboard/${business.id}/businesspage`),
    //  },
    {
      name: "menuitem",
      visible: true,
      description: "Share business with QR-code",
      icon: "QrcodeIconOutline",
      action: () => stampcardService.showBusinessQrcode({ business }),
    },
    {
      name: "menuitem",
      visible: true,
      description: `${active ? "Archive" : "Activate"} card`,
      icon: "ArchiveIconOutline",
      action: () => toggleCard(business.id),
    },
    {
      name: "menuitem",
      visible: true,
      description: "Delete card",
      icon: "TrashIconOutline",
      action: () => openDeleteCardModal(),
    },
  ] as widget[];

  $: if ($isOwner.isSuccess) {
    console.log($isOwner.data);
  }
</script>

<div class="head flex gap-3">
  <div class="aspect-square bg-opacity-90 w-16 h-16 rounded-xl">
    {#key demoimagesrc}
      <Image
        demoimage={creatormode}
        demosrc={demoimagesrc}
        style="!object-contain rounded-2xl"
        src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{business.id}/logo/{business.id}.png"
      />
    {/key}
  </div>
  <div
    class="flex flex-auto info dark:!text-{text_color == 'black'
      ? '[black]'
      : '[#fff]'}  text-{text_color}"
  >
    <div class="flex-auto">
      <h3 class={`truncate	font-semibold	capitalize max-w-[150px]`}>
        {name}
      </h3>
      {#if $businessType.isLoading}
        <p />
      {:else if $businessType.isError}
        <p />
      {:else}
        <p class="capitalize">{$businessType.data}</p>
      {/if}
    </div>
    <div>
      <div
        on:keydown
        on:click={() =>
          stampcardService.showCardOptions({
            business,
            widgetTree,
          })}
        class="btn btn-square btn-ghost btn-sm"
      >
        <Icon type="DotsVerticalIconOutline" />
      </div>
    </div>
  </div>
</div>
