<script lang="ts">
  import { modals } from "$lib/stores/systemModals.store";
  import Alert from "$lib/components/system/Alert.svelte";
  import Confirm from "$lib/components/system/Confirm.svelte";
  import Menu from "$lib/components/system/Menu.svelte";

  const modalTypes = {
    alert: Alert,
    menu: Menu,
    confirm: Confirm,
  };

  const removeModal = (id: string) => {
    setTimeout(() => {
      $modals = $modals.filter((m) => m.id != id);
      // $modals = [...$modals.splice(index, 1)];
    }, 300);
  };
</script>

{#each $modals as modal, index (modal.id)}
  <svelte:component
    this={modalTypes[modal.type]}
    {...modal}
    on:remove={() => removeModal(modal.id)}
  />
{/each}
<slot />
