<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import { formatter } from "src/utils/currencyFormatter";
  let initialRevenue = 10000; // Initial monthly revenue without Melon Cards
  let returningCustomerIncrease = 5; // Percentage increase in returning customers
  let profitGrowthRange = [25, 95]; // Range of profit growth percentages

  // let profitGrowth = (initialRevenue * returningCustomerIncrease) / 100;
  $: minProfit =
    initialRevenue *
    (1 + profitGrowthRange[0] / 100 + returningCustomerIncrease / 100);
  $: maxProfit =
    initialRevenue *
    (1 + profitGrowthRange[1] / 100 + returningCustomerIncrease / 100);
  $: profitRange = [minProfit, maxProfit];

  // const formattedAmount = formatter.format(amount);
</script>

<div class=" grid grid-cols-1 w-full md:grid-cols-2 gap-10">
  <div class="  text-black w-full p-8 space-y-8 rounded-[40px] bg-[#fff4Df]">
    <p class="text-3xl font-bold mb-6">Revenue Calculator</p>

    <div>
      <span class="block mb-2">
        Initial Monthly Revenue: <b>{formatter.format(initialRevenue)}</b>
      </span>
      <div class="flex gap-5">
        &dollar;0
        <input
          type="range"
          min="0"
          max="50000"
          step="1000"
          bind:value={initialRevenue}
          class="range-primary range"
        />
        &dollar;50k
      </div>
    </div>

    <div class="text-black">
      <span class="block mb-2">Returning Customer Increase (%): </span>
      <input
        type="number"
        bind:value={returningCustomerIncrease}
        min="2"
        max="100"
        step="1"
        class="bg-opacity-30 bg-white rounded-lg p-2 border focus:border-blue-500 focus:outline-none"
      />
    </div>
  </div>

  <div class="flex flex-col gap-4 relative">
    <div class=" text-black p-10 bg-[#c3e3be] rounded-3xl space-y-2">
      <div
        class="absolute top-3 right-4 tooltip-accent tooltip-left tooltip"
        data-tip={`
Results are estimates based on industry research. Actual outcomes may vary based on card usage and promotions. Melon Cards is not liable for individual results. For personalized tips, contact us through our website.
									`}
      >
        <span class="text-primary">
          <Icon type="InformationCircleIconOutline" />
        </span>
      </div>

      <p class="font-bold text-xl text-center">
        Your monthly revenue could grow between <span />
      </p>
      <h1 class="text-4xl font-semibold mb-4 text-center">
        <span class="font-black"> {formatter.format(profitRange[0])}</span>
        -
        <span class="font-black"> {formatter.format(profitRange[1])}</span>
      </h1>
    </div>
    <div class="grid grid-col-1 gap-4 px-8 rounded-3xl">
      <h2 class="text-2xl font-bold">Summary</h2>
      {#key initialRevenue || returningCustomerIncrease}
        <div
          class="col-span-1 flex justify-between pb-5 border-primary border-opacity-5 border-b-2"
        >
          <span class="font-bold">Increase of your monthly value</span>
          <span>
            <span class="font-bold"
              >{formatter.format(profitRange[0] - initialRevenue)}</span
            >
            -
            <span class="font-bold"
              >{formatter.format(profitRange[1] - initialRevenue)}</span
            >
          </span>
        </div>
        <div class="col-span-1 flex justify-between">
          <span class="font-bold"> Retention </span>
          <span class="font-bold">
            {!returningCustomerIncrease ? 0 : returningCustomerIncrease}%
          </span>
        </div>
      {/key}
    </div>
  </div>
</div>
