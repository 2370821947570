<script lang="ts">
	import { createEventDispatcher } from "svelte";
	export let placeholderText;
	export let type: "profile" | "banner" | "other";
	const dispatch = createEventDispatcher();
	let imgelement, files;
	const showImage = async (e) => {
		files = e.target.files;
		dispatch("fileselect", files);

		if (FileReader && files && files.length) {
			var fr = new FileReader();
			fr.onload = function () {
				imgelement = fr.result;
			};
			fr.readAsDataURL(files[0]);
		}
	};
</script>

{#if !files}
	<label for="fileinput">
		<div
			class="relative flex items-center justify-center w-full p-3 border-dashed border-2 {type ==
			'banner'
				? 'aspect-[4/2]'
				: 'aspect-square'} rounded-2xl"
		>
			<div
				class="absolute top-0 flex items-center justify-center w-full h-full text-xl px-3 text-center"
			>
				{placeholderText}
			</div>
		</div>
	</label>
{:else}
	<div
		class="relative flex items-center justify-center w-full p-3 border-dashed border-2 {type ==
		'banner'
			? 'aspect-[4/2]'
			: 'aspect-square'} rounded-2xl"
	>
		<button
			class="absolute top-0 right-0 btn btn-primary btn-square btn-sm"
			on:click={() => {
				dispatch("cancel");
				files = undefined;
			}}>x</button
		>
		<label for="fileinput" class="h-full w-full">
			{#if type == "profile"}
				<img
					class="custom_outline z-10 object-cover w-40 h-40 rounded-full"
					src={imgelement}
					alt=""
				/>
			{:else if type == "banner"}
				<img
					class="custom_outline z-10 object-cover aspect-[4/2] w-full rounded-2xl"
					src={imgelement}
					alt=""
				/>
			{:else}
				<img
					class="custom_outline z-10 object-cover w-full h-full rounded-2xl"
					src={imgelement}
					alt=""
				/>
			{/if}
		</label>
	</div>
{/if}
<input
	on:change={showImage}
	bind:files
	hidden
	id="fileinput"
	type="file"
	accept="image/*"
/>
