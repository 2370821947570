<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import WidgetTreeBuilder from "./WidgetTreeBuilder.svelte";
  export let title = "Alert";
  export let position: "" | "bottom" = "";
  export let message: string = "";
  export let type = "";
  export let widgetTree = [];
  export let manual = false;

  let alert;

  const dispatch = createEventDispatcher();

  onMount(() => {
    if (manual) return;
    alert.showModal();
  });

  const handleButtonClick = () => {
    dispatch("remove");
    alert.close();
  };
</script>

<!-- Open the modal using ID.showModal() method -->
<dialog
  bind:this={alert}
  out:fade|local={{ duration: 300 }}
  class="fixed left-0 h-screen top-0 sm:modal-middle modal-bottom modal"
>
  <div
    in:fade|local={{ duration: 300 }}
    out:fade|local={{ duration: 300 }}
    on:keydown
    class="bg-black bg-opacity-50 h-full w-full absolute top-0 left-0"
    on:click={handleButtonClick}
  />
  <form
    in:fly|local={{ y: 300, delay: 200, duration: 300 }}
    out:fly|local={{ y: 200, duration: 300 }}
    method="dialog"
    class=" transition-none translate-y-0 mx-auto modal-box {position ==
    'bottom'
      ? 'pb-12'
      : ''}"
  >
    <h3 class="text-base-content font-bold text-lg capitalize">
      {title}
    </h3>
    <p class="text-base-content py-4">
      {message}
    </p>
    <ul class="bg-base-200 gap-3 w-full menu mb-7 rounded-box">
      <WidgetTreeBuilder on:close={handleButtonClick} {widgetTree} />
    </ul>
    <div class="modal-action grid grid-cols-1">
      <button on:click={handleButtonClick} class="btn btn-primary">Okay</button>
    </div>
  </form>
</dialog>

<style>
  dialog::backdrop {
    @apply bg-transparent;
  }
  dialog {
    @apply h-screen top-0 pb-0;
  }
</style>
