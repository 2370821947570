<script lang="ts">
	import Image from "$lib/components/Image.svelte";
	import { app_assets } from "$lib/constants";
	export let id;
</script>

<a {id}>
	<section class="pt-24 pb-60	overflow-hidden">
		<div class="container px-4 mx-auto">
			<h2
				class="mb-20 text-4xl md:text-5xl xl:text-6xl font-black font-heading text-center tracking-px-n leading-none"
			>
				How it works
			</h2>
			<div class="flex flex-wrap -m-16 md:-m-3">
				<div
					class="w-full mb-[150px] md:w-1/2 xl:w-1/3 p-16 md:p-3"
				>
					<div
						class="px-10 pt-11 text-center bg-[#FFEBF5] rounded-[40px] h-96 rounded-4xl"
					>
						<h3
							class="mb-3 text-xl font-bold font-heading leading-normal"
						>
							Create a custom stamp
							card
						</h3>
						<p
							class="mb-10 text-gray-600 font-medium leading-relaxed"
						>
							Allow customers to scan
							their digital stamp card
							at your business and
							collect stamps with each
							visit.
						</p>
						<Image
							style="mx-auto h-72 object-cover rounded-[40px] shadow-2xl transform hover:translate-y-3 transition ease-in-out duration-1000"
							src="{app_assets}/images/stampcard.png"
							alt=""
						/>
					</div>
				</div>
				<div
					class="w-full mb-[150px] md:w-1/2 xl:w-1/3 p-16 md:p-3"
				>
					<div
						class="px-10 pt-11 text-center bg-[#E3F4F2] rounded-[40px] h-96"
					>
						<h3
							class="mb-3 text-xl font-bold font-heading leading-normal"
						>
							Effortless Stamping
						</h3>
						<p
							class="mb-10 text-gray-600 font-medium leading-relaxed"
						>
							Update loyalty stamps
							with our simple scanner
							in the app. Save time
							and provide a seamless
							experience for your
							customers.
						</p>
						<Image
							style="mx-auto h-72 object-cover rounded-[40px] shadow-2xl transform hover:translate-y-3 transition ease-in-out duration-1000"
							src="{app_assets}/images/scanner.png"
							alt=""
						/>
					</div>
				</div>
				<div
					class="w-full mb-[150px] md:w-1/2 xl:w-1/3 p-16 md:p-3"
				>
					<div
						class="px-10 pt-11 text-center bg-[#FFF4DF] rounded-[40px] h-96 rounded-4xl"
					>
						<h3
							class="mb-3 text-xl font-bold font-heading leading-normal"
						>
							Rewarding Loyalty
						</h3>
						<p
							class="mb-10 text-gray-600 font-medium leading-relaxed"
						>
							Delight customers with
							exclusive vouchers when
							their stamp card is
							full. Encourage repeat
							visits and foster
							long-term loyalty.
						</p>
						<Image
							style="mx-auto h-72 object-cover rounded-[40px] shadow-2xl transform hover:translate-y-3 transition ease-in-out duration-1000"
							src="{app_assets}/images/vouchers.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</a>
