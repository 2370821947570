<script lang="ts">
  // import { goto } from "@roxi/routify";
  import { flip } from "svelte/animate";
  import Stampcard from "$lib/features/stampcard/components/Stampcard.svelte";
  import { supabase } from "$lib/db";
  import { useQuery, useQueryClient } from "@sveltestack/svelte-query";
  import { cubicInOut } from "svelte/easing";
  import Observer from "$lib/components/Observer.svelte";
  import WalletService from "../services/Wallet.service";
  import fadeScale from "src/utils/fadescale";
  import { fly } from "svelte/transition";
  import { onDestroy, onMount } from "svelte";
  import { Capacitor } from "@capacitor/core";
  import { navigate } from "svelte-routing";

  export let selectedCategory: string;
  export let activeCards = true;
  const walletService = new WalletService();

  let skipLoading = true;
  let user = supabase.auth.user();
  let wallet;
  let walletData: any[];

  const queryClient = useQueryClient();
  onDestroy(() => {
    cards.unsubscribe();
  });

  onMount(() => {
    queryClient.invalidateQueries(["wallet"]);
  });
  const cards = supabase
    .from(`cards:user_id=eq.${user.id}`)
    .on("*", (payload) => {
      if ($wallet.isSuccess) {
        $wallet.data.find((card) => card.id == payload.new.id).stamps =
          payload.new.stamps;
      }
    })
    .subscribe();

  $: if (user) {
    wallet = useQuery(
      ["wallet", user.id],
      () => walletService.getWallet(user.id, { activeCards, selectedCategory }),
      {
        cacheTime: 0, // 24 hours
      }
    );
  }

  $: if (activeCards.toString() || selectedCategory) {
    wallet = useQuery(
      ["wallet", user.id],
      () => walletService.getWallet(user.id, { activeCards, selectedCategory }),
      {
        // cacheTime: 1000 * 60 * 60 * 1, // 24 hours
      }
    );
  }

  $: if ($wallet.isSuccess) {
    walletData = $wallet.data;
  }
</script>

<div in:fly={{ y: 10 }} class=" w-[0px] flex-auto flex">
  {#if user}
    {#if $wallet.isLoading}
      <span class="hidden">
        {(skipLoading = false)}
      </span>
      <div
        class="no-scrollbar scroll-pl-7 pb-7 px-7 space-x-4 snap-x overflow-x-auto whitespace-nowrap"
      >
        {#each Array(3) as _}
          <div class="inline-block snap-start min-w-[250px]">
            <div
              class="aspect-[4/6] rounded-2xl bg-gray-200 animate-pulse bg-opacity-25 w-[305px]"
            />
          </div>
        {/each}
      </div>
    {:else if $wallet.isError}
      <div class="px-8 bg-red-500">
        <div class="p-4 rounded-xl bg-base-300">omething went wrong</div>
      </div>
    {:else}
      <div
        class="no-scrollbar relative scroll-pl-7 min-h-[500px] px-7 space-x-4 w-full overflow-x-auto {$wallet
          .data.length == 1
          ? 'justify-center'
          : ''}   whitespace-nowrap"
      >
        {#each walletData as card, index (card.id)}
          <div
            animate:flip|global={{ delay: 300, duration: 300 }}
            class="transition-all inline-block snap-start min-w-[300px]"
          >
            <div class="transition-all cardwrap">
              <Observer css threshold={0.1}>
                <Stampcard
                  on:archivecard={(e) =>
                    (walletData = [
                      ...walletData.filter((c) => c.id !== card.id),
                    ])}
                  on:delete={(e) => {
                    walletData = [
                      ...walletData.filter((c) => c.id !== card.id),
                    ];
                  }}
                  active={card.active}
                  cardData={card}
                  business={card.info}
                />
              </Observer>
            </div>
          </div>
        {/each}
        {#if !walletData.length && activeCards == false}
          <div>
            <div
              class="h-16 flex items-center justify-center max-w-sm card bg-base-200"
            >
              No archived cards
            </div>
          </div>
        {/if}
        {#if activeCards}
          {#if Capacitor.isNative}
            <div
              role="button"
              tabindex="0"
              on:keydown
              on:click={() => navigate("/scanner")}
              class="cursor-pointer align-top inline-block snap-start min-w-[250px]"
            >
              <span>
                <div
                  class=" aspect-[4/6] border-2 border-dashed text-gray-500 flex flex-col items-center justify-center h-[450px] rounded-3xl w-full"
                >
                  <span class=" pb-4 aspect-square w-20 grid grid-cols-1">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>
                  <span> Add a new card </span>
                </div>
              </span>
            </div>
          {:else}
          
            <div
              role="button"
              tabindex="0"
              on:keydown
              class="cursor-pointer align-top inline-block snap-start min-w-[250px]"
              in:fadeScale={{
                duration: 400,
                easing: cubicInOut,
                baseScale: 0.9,
              }}
            >
              <span>
                <div
                  class=" aspect-[4/6] border-2 border-dashed text-gray-500 flex flex-col items-center justify-center h-[450px] rounded-3xl w-full"
                >
                  <span class=" pb-4 aspect-square w-20 grid grid-cols-1">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>
                  <span class="max-w-full break-normal">Open your camera  app</span>
                  <span>
                    and scan the QR code!
                  </span>
                </div>
              </span>
            </div>
          {/if}
        {/if}
      </div>
    {/if}
  {:else}
    sign in
  {/if}
</div>
