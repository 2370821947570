<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import Image from "$lib/components/Image.svelte";
  import { format } from "timeago.js";

  export let transaction;
</script>

<li
  class="gap-4 rounded-xl dark:bg-black dark:bg-opacity-10 bg-opacity-80 bg-gray-100 p-3 flex"
>
  <div
    class=" rounded-2xl h-full aspect-square flex items-center p-2 justify-center w-10"
    style={`background: ${transaction.business.primary_color}`}
  >
    <!-- <Icon type="SwitchHorizontalIconOutline" /> -->
    <Image
      style="!object-contain"
      src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{transaction
        .business.id}/logo/{transaction.business.id}.png"
    />
  </div>

  <div class="flex-auto">
    {transaction.type} @<span class="font-semibold"
      >{transaction.business.name}</span
    >
    <br />
    <span class="font-light flex text-gray-700 dark:text-gray-200 text-sm">
      {format(transaction.created_at, "en_US")}
    </span>
  </div>
</li>
