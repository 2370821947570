// import { FormTree } from "./types";
import formMethods from "../components/system/formMethods/index";

export default ({ business_id }) => {
  const form = {
    method: formMethods.createFoodItem,
    business_id,
    fields: [
      {
        id: "p_name",
        name: "imageinput",
        type: "text",
        required: true,
        label: "Name of the food",
        placeholder: "Sushi",
      },
      {
        id: "p_name",
        name: "input",
        type: "text",
        required: true,
        label: "Name of the food",
        placeholder: "Sushi",
      },
      {
        name: "textarea",
        id: "p_description",
        type: "text",
        required: true,
        label: "A short description of the food",
        placeholder: "Catch of the day",
      },
      {
        id: "p_price",
        name: "input",
        type: "number",
        label: "price &times; 100",
      },
    ],
  };

  return form;
};
