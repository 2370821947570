<script lang="ts">
  import { getNotificationsContext } from "svelte-notifications";
  import { createNotification } from "src/utils/createNotification";
  import { supabase } from "$lib/db";
  import { useQuery } from "@sveltestack/svelte-query";
  import Icon from "$lib/components/Icon.svelte";
  import { createEventDispatcher } from "svelte";

  export let business;
  export let style;
  export let isBusinessPage = false;

  // const { addNotification } = getNotificationsContext();
  const notificationContext = getNotificationsContext();
  const notification = createNotification(notificationContext);
  const dispatch = createEventDispatcher();

  let cardExist;
  let clicked = false;
  let user = supabase.auth.user();

  const addToWallet = async (business_id) => {
    const { data, error } = await supabase.rpc("add_to_wallet", {
      p_business_id: business_id,
    });
    if (error)
      throw notification.dispatch(
        "error",
        "Something went wrong. Please try again later."
      );
    // $cardExist.data.push({ business, stamps: 0 });
    clicked = true;
    notification.dispatch("success", "Successfuly added card to wallet");
    dispatch("cardcreated");
  };
  //check if card exist with business id on server
  const checkCard = async (business_id) => {
    const { data, error } = await supabase
      .from("cards")
      .select("*")
      .eq("business_id", business_id)
      .eq("user_id", user.id);

    if (error) throw error;
    return data;
  };
  $: if (user) {
    cardExist = useQuery(
      ["cardExist", business.id],
      () => checkCard(business.id),
      { retry: 0 }
    );
  }
</script>

{#if cardExist}
  {#if $cardExist.isLoading}
    <button class="btn">Loading..</button>
  {:else if $cardExist.isError}
    {#if user}
      {#if isBusinessPage}
        <button
          style="color:{business.data.text_color};background: {business.data.primary_color}"

          class="btn btn-primary border-none"
          on:click|stopPropagation={() => addToWallet(business.data.id)}
        >
          <Icon type="PlusIconOutline" />Add card</button
        >
      {:else}
        <button
          class="btn text-white gap-2 btn-sm bg-white bg-opacity-20 backdrop-blur-xl border-none"
          on:click|stopPropagation={() => addToWallet(business.id)}
          ><Icon type="PlusIconOutline" /> Add card</button
        >
      {/if}
    {/if}
  {:else if $cardExist.data.length}
    <span />
    <div class="w-full">
      <div
        class="mx-auto sticky top-20 w-full mb-8 bg-base-300 flex flex-col gap-4 rounded-[30px] p-4"
      >
        <div class="head h-20 flex gap-4 w-full">
          <div
            class="p-3 w-20 h-20 bg-base-200 rounded-3xl"
            style="color:{business.data.text_color};background: {business.data
              .primary_color}"
          >
            <Image
              style="!object-contain  h-full w-full rounded-none"
              src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{business
                .data.id}/logo/{business.data.id}.png"
            />
          </div>
          <div>
            <Title padding={false} style="capitalize">
              {business.data.name}
              <p slot="subtext">
                {business.data.type.name}
              </p>
            </Title>
          </div>
        </div>
        {#if user}
          <!-- <button class="btn btn-primary border-none" style="color:{$business.data.text_color};background: {$business.data.primary_color}"> -->
          <!--   add stampcard to wallet</button -->
          <!-- > -->
        {:else}
          <button class="btn">Create an account to save stamps!</button>
        {/if}
      </div>
    </div>
  {:else if !clicked}
    {#if user}
      {#if isBusinessPage}
        <!-- style="color:{business.text_color};background: {business.primary_color}" -->

        <button
          class="btn btn-primary border-none"
          on:click|stopPropagation={() => addToWallet(business.id)}
        >
          <Icon type="PlusIconOutline" />Add card</button
        >
      {:else}
        <button
          class="btn text-white gap-2 btn-sm bg-white bg-opacity-20 backdrop-blur-xl border-none"
          on:click|stopPropagation={() => addToWallet(business.id)}
          ><Icon type="PlusIconOutline" /> Add card</button
        >
      {/if}
    {/if}
  {/if}
{/if}
