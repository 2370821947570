<script lang="ts">
  import { fly } from "svelte/transition";
  import type { Icon } from "src/utils/icontypes";
  import SvgIcon from "./Icon.svelte";
  interface INotification {
    type?: string;
    text?: string;
  }
  export let notification: INotification = {};
  export let onRemove = () => {};
  export let type: Icon = "";
  const handleButtonClick = () => {
    onRemove();
  };
  // TODO: swipe to dismiss -> interactjs
</script>

<div
  in:fly|local={{ y: -20, duration: 400, delay: 0 }}
  out:fly|local={{ y: -20, duration: 400, delay: 0 }}
  class={`${
    !notification.type ? "regular" : notification.type
  }  w-full flex backdrop-blur  !bg-opacity-70 border-2 rounded-2xl relative my-2 z-40 `}
>
  <div
    on:keydown
    on:click={handleButtonClick}
    class="aspect-square flex items-center justify-center close actions w-16"
  >
    <SvgIcon type="InformationCircleIconOutline" />
  </div>
  <div class="flex-auto py-3 flex flex-col justify-center info">
    <p class="font-light">{notification.text}</p>
  </div>

  <div
    on:keydown
    on:click={handleButtonClick}
    class=" aspect-square flex items-center justify-center close actions w-14"
  >
    <SvgIcon type="XIconOutline" />
  </div>
</div>

<style>
  .regular {
    @apply bg-white text-blue-900;
  }

  .error {
    @apply bg-base-200;
  }

  .success {
    @apply bg-base-200  font-normal;
  }

  .warning {
    @apply bg-base-200  border-orange-500  font-normal;
  }
</style>
