<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import Title from "$lib/components/Title.svelte";
  import { supabase } from "$lib/db";
  import Authmodal from "$lib/features/auth/components/Authmodal.svelte";
  import { createEventDispatcher } from "svelte";
  import Addtowalletbtn from "./Addtowalletbtn.svelte";
  import { baseURL } from "$lib/constants";
  export let business;
  export let params
  let SigninToggle = false;
  const user = supabase.auth.user();
  const dispatch = createEventDispatcher();
  console.log(business);
</script>

<div class="w-full">
  <div
    class="mx-auto sticky top-20 w-full mb-8 bg-base-300 flex flex-col gap-4 rounded-[30px] p-4"
  >
    <div class="head h-20 flex gap-4 w-full">
      <div
        class="p-3 w-20 h-20 bg-base-200 rounded-3xl"
        style="color:{$business.data.text_color};background: {$business.data
          .primary_color}"
      >
        <Image
          style="!object-contain  h-full w-full rounded-none"
          src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{$business
            .data.id}/logo/{$business.data.id}.png"
        />
      </div>
      <div>
        <Title padding={false} style="capitalize">
          {$business.data.name}
          <p slot="capitalize subtext">
            {$business.data.type.name}
          </p>
        </Title>
      </div>
    </div>
    {#if user}
      <Addtowalletbtn
        on:cardcreated={() => dispatch("cardcreated")}
        isBusinessPage
        business={$business}
        style=""
      />
    {:else}
      <button on:click={() => (SigninToggle = true)} class="btn btn-primary">
        Sign in to save card
      </button>
    {/if}
  </div>
</div>
{#if !user && SigninToggle}
  <Authmodal
    redirectTo="{baseURL}/page/{params.id}"
    on:modalclose={() => (SigninToggle = false)}
  />
{/if}
