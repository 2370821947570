<script lang="ts">

  import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
  import Appbar from "$lib/components/Appbar.svelte";
  import { onDestroy, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { Plugins } from "@capacitor/core";

  const { Permissions } = Plugins;

  const prepare = () => {
    BarcodeScanner.prepare();
  };
  const checkPermission = async () => {
    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.granted) {
      // the user granted permission
      return true;
    }

    return false;
  };

  onMount(() => {
    checkPermission();
  });
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?://)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator

    // Check if the URL contains a protocol (http or https)
    if (!/^https?:\/\//i.test(urlString)) {
      return false;
    }

    return !urlPattern.test(urlString);
  };
  const startScan = async () => {
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan();

    if (result.hasContent) {
      if (isValidUrl(result.content)) {
        const path = new URL(result.content).pathname;
        return navigate(path);
      }

      return navigate(result.content);
    }
  };

  const stopScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  const askUser = () => {
    prepare();

    // const c = confirm("Do you want to scan a barcode?");
    startScan();
    // melon.confirm({
    //   title: "Scanner",
    //   message: "Do you want to scan a QR-code?",
    //   yes: () => startScan(),
    //   no: () => {
    //     stopScan();
    //     $goto("/");
    //   },
    // });
  };

  onDestroy(() => {
    stopScan();
  });
  askUser();
</script>

<Appbar isFixed />
<div class="absolute top-0 h-full w-full  ">
  <div
    class=" w-full flex flex-col items-center h-full justify-center px-7 absolute top-0"
  >
    <div class="max-w-[200px] relative z-10 text-white text-center mb-3">
      Align the QR code within the frame to scan
    </div>
    <div
      class="target aspect-square min-h-[300px] w-full relative max-w-[300px] rounded-3xl "
    >
      <div class="corner" />
      <div class="corner absolute right-0 top-0" />
      <div class="corner absolute bottom-0 left-0" />
      <div class="corner absolute right-0 bottom-0" />
      <div
        class="scannereffect absolute transition-all ease-in top-[] h-3 w-[80%] left-1/2 -translate-x-1/2 bg-white rounded-xl"
      />
    </div>
  </div>
</div>

<style>
  .target {
    box-shadow: 0px 0px 0px 100vh rgba(0, 0, 0, 0.5);
  }
  .scannereffect {
    @apply shadow-2xl !shadow-white;

    animation: 2s scananimation infinite;
  }
  img {
    filter: drop-shadow(0px 0px 10px white);
  }
  @keyframes scananimation {
    0% {
      top: 10%;
    }
    50% {
      top: 85%;
    }
    100% {
      top: 10%;
    }
  }
</style>
