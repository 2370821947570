<script lang="ts">
	import type { Icon as IIcon } from "src/utils/icontypes";
	import { createEventDispatcher } from "svelte";
	import Icon from "./Icon.svelte";

	export let icon: IIcon;
	export let action: () => any;
	export let visible: boolean = true;
	export let description: string;

	const dispatch = createEventDispatcher();
</script>

{#if visible}
	<li
		on:click={() => {
			action();
			dispatch("close");
		}}
		class=""
	>
		<span class="!rounded-xl">
			<Icon type={icon} />{description}
		</span>
	</li>
{/if}
