
<script lang="ts">

  import Title from "$lib/components/Title.svelte";
  import { supabase } from "$lib/db";
    import { melon } from "$lib/stores/systemModals.store";
  import { useQuery } from "@sveltestack/svelte-query";

  let username = "";
  const user = supabase.auth.user();
  const handleChangeUsername = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .update({ username: username.toLowerCase() })
      .eq("id", user.id);
    if (error) throw melon.alert({title:"error", message: error.message.includes('duplicate') ? 'User already exist': 'Something went wrong, try again later'});
    melon.alert({message: "successfully updated your username"});
    return data;
  };

  const getUsername = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select("username")
      .eq("id", user.id)
      .single();

    if (error) throw error;
    return data.username;
  };

  const myUsername = useQuery("myusername", () => getUsername());
  let once = true;
  $: if ($myUsername.isSuccess && once) {
    once = false;
    username = $myUsername.data;
  }
</script>

  <div class="max-w-[920px] mx-auto container py-7 ">
  <div class="w-full max-w-xl lg:mx-0 mx-auto">
    <Title>Change username</Title>
    <form
      on:submit|preventDefault={handleChangeUsername}
      action=""
      class="mt-7 grid grid-cols-1 px-7 gap-4"
    >
      <div class="flex flex-col gap-2">
        <label for="newemail">Username</label>
        <input
          class="input input-bordered focus:outline-primary"
          bind:value={username}
          type="text"
          placeholder="new username"
        />
      </div>
    <div>
      <button class="btn btn-primary" type="submit">Save username</button>
    </div>
    </form>
  </div>
</div>
