<script lang="ts">
	import type { Icon } from "src/utils/icontypes";
	import * as heroicon from "@codewithshin/svelte-heroicons";

	// interface ICustomIcon extends Icon {}
	export let type: Icon = "";
</script>

<svelte:component this={heroicon[type]} style="height:100%;width:100%;" />

<style>
	.svg {
		width: 100% !important;
		height: 100% !important;
	}
</style>
