<script lang="ts">
  import formfields from "$lib/components/system/formfields";
  import { createEventDispatcher } from "svelte";

  export let form;

  let fields = {};

  let formEl;
  fields = { ...fields, p_business_id: form.business_id };
  const dispatch = createEventDispatcher();
  let disableButton = false;
</script>

<form
  bind:this={formEl}
  on:submit|preventDefault={() => {
    disableButton = true;
    form.method(fields, () => {
      dispatch("close");
    });
  }}
>
  {#each form.fields as field, fieldIndex}
    <div class="grid grid-cols-1 gap-5">
      {#if "name" in field}
        {#if field.name == "switch"}
          <svelte:component
            this={formfields[field.name]}
            on:change={(e) => {
              fields = { ...fields, [field.id]: e.detail };
            }}
            props={field}
            {...field}
          />
        {:else}
          <svelte:component
            this={formfields[field.name]}
            on:input={(e) => {
              fields = { ...fields, [field.id]: e.detail };
            }}
            props={field}
            {...field}
          />
        {/if}
      {:else}
        <span class="hidden" />
      {/if}
    </div>
  {/each}
  <button disabled={disableButton} class="btn btn-primary">submit form</button>
</form>
