<script lang="ts">
	import "../../app.css";
	import Notifications from "svelte-notifications";
	import NotificationToast from "$lib/components/NotificationToast.svelte";
	// import { goto } from "@roxi/routify";
	import { supabase } from "$lib/db";
	import Navbar from "$lib/components/Navbar.svelte";
	import Icon from "$lib/components/Icon.svelte";
	import Appsidebar from "$lib/components/Appsidebar.svelte";
	import { onRefresh } from "$lib/stores/pullToRefresh.store";
	import Loading from "$lib/components/Loading.svelte";
	import Ptr from "$lib/features/ptr/components/ptr.svelte";
	import { navigate } from "svelte-routing";
	const toast = NotificationToast as any;

	let menuIsOpen = false;
	supabase.auth.onAuthStateChange(async (event, session) => {
		if (event == "SIGNED_OUT") {
			navigate("/signin");
			// $goto("/resetpassword/newpassword");
		}
	});
	//  const refreshing = onRefresh({
	//    callback(state) {
	//      setTimeout(() => {
	//        state.set(false);
	//      }, 100);
	//    },
	//  });
</script>

<Notifications item={toast}>
	<!-- <Ptr /> -->
	<!-- {#if $refreshing} -->
	<!-- <div class=" h-screen w-screen grid content-center"> -->
	<!-- <Loading /> -->
	<!-- </div> -->
	<!-- {:else} -->
	<div class="  flex">
		<slot />
	<Navbar />
	</div>
	<!-- {/if} -->
</Notifications>
