<script>
	import Title from "$lib/components/Title.svelte";
	import Appbar from "$lib/components/Appbar.svelte";
	import { supabase } from "$lib/db";
	import { getNotificationsContext } from "svelte-notifications";

	const { addNotification } = getNotificationsContext();

	let email;
	const triggerNotification = (isSucces, msg) => {
		addNotification({
			text: msg,
			position: "top-center",
			type: isSucces ? "success" : "error",
			removeAfter: 2000,
		});
	};
	const handleResetPassword = async () => {
		const { data, error } = await supabase.auth.api.resetPasswordForEmail(
			email
		);

		if (error)
			return triggerNotification(
				false,
				"Something went wrong, please try again."
			);
		triggerNotification(true, "Check your e-mail for the recovery link");
		document.querySelector("form").reset();
	};
</script>

<Appbar backbutton/>
<div class="max-w-[920px] mx-auto container py-7 h-screen ">
	<div class="pt-12">
		<Title>Reset password</Title>
	</div>
	<form
		on:submit|preventDefault={handleResetPassword}
		action=""
		class="grid gap-2 w-full lg:w-full px-7"
	>
		<div class="form-control">
			<label for="email" class="label"
				><span class="label-text">Email</span>
			</label>
			<input
				class="input input-bordered focus:outline-primary"
				id="email"
				type="email"
				bind:value={email}
				placeholder="john@doe.com"
			/>
		</div>
		<a class="font-semibold mt-4" href="/signin">Back to signin</a>
		<button class="mt-7 btn btn-primary" type="submit" value="reset password">
			reset password
		</button>
	</form>
</div>
