<script lang="ts">
	import Icon from "$lib/components/Icon.svelte";
	import Image from "$lib/components/Image.svelte";
	import { app_assets } from "$lib/constants";
	import { melon } from "$lib/stores/systemModals.store";
	import { formatter } from "src/utils/currencyFormatter";
	export let id;
	let isAnualPricing = false;
	const pricingTable = [
		{
			tier: "Basic",
			monthlyPricing: 30,
			active: true,
			annualPricing: 24,
			c2a: "Start your loyalty journey now.",
			description:
				"Ideal for small businesses with a single location and limited budget.",
			features: [
				"1 Stamp card",
				"1 Location ($15 per extra location)",
				"1 admin",
				"Up to 50 active stamp card",
				"Custom branding",
			],
			popular: false,
			missing: [
				"Dashboard + customer insights",
				"Extra customisation possibilities",
			],
		},
		{
			tier: "Advanced",
			monthlyPricing: 45,
			annualPricing: 36,
			active: true,
			c2a: "Upgrade for enhanced benefits.",
			description:
				"Suitable for growing businesses with multiple locations.",
			features: [
				"1 stampcard",
				"2 locations ($10 per extra location)",
				"2 admins",
				"Unlimited active stamp cards",
				"Custom branding",
			],
			popular: true,
			missing: [
				"Extra customisation possibilities (coming soon)",
				"Dashboard + customer insights",
			],
		},
		{
			tier: "Professional",
			monthlyPricing: 55,
			active: false,
			annualPricing: 44,
			c2a: "Maximize loyalty rewards today.",
			description:
				"Perfect for established businesses with a loyal customer base.",
			features: [
				"2 stampcards",
				"2 locations ($7 per extra location)",
				"Unlimited admins",
				"Unlimited active stamp cards",
				"Custom branding",
				"Extra customisation possibilities",
				"Dashboard + customer insights",
			],
			popular: false,
			missing: [],
		},
	];

	const enterpriseColumn = {
		tier: "Enterprise",
		description:
			"Designed for enterprises with hundreds of locations and advanced needs.",
		features: [
			"Stand-alone white label app",
			"Unlimited stampcard",
			"Unlimited locations",
			"Unlimited admins",
			"Unlimited active Stamp cards",
			"Premium branding",
			"Extra customisation possibilities",
			"Dashboard + customer insights",
		],
		popular: false,
		missing: [],
	};

	const openPlans = () => {
		melon.alert({
			title: "Choose your subscription",
			message: "",
			wide: true,
			hideOK: true,
			widgetTree: [
				{
					publishable_key: import.meta.env
						.VITE_PUBLISHABLE_KEY,
					name: "subscriptionplans",
				},
			],
		});
	};
</script>

<section {id} class="pt-24 pb-32 overflow-hidden">
	<div class="container px-4 mx-auto">
		<h2
			class="mb-6 max-w-5xl text-6xl md:text-6xl xl:text-10xl font-black font-heading tracking-px-n leading-none"
		>
			Simple and Transparent Pricing Plans for Every Business
		</h2>
		<div class="mb-16 flex flex-wrap justify-between -m-4">
			<div class="w-auto p-4">
				<div class="md:max-w-md">
					<p
						class="text-lg text-gray-900 dark:text-base-content  leading-relaxed"
					>
						Choose your tier, customize
						branding, and gain valuable
						customer insights. Elevate your
						business with our flexible plans
						today
					</p>
				</div>
			</div>
			<div class="w-auto p-4">
				<div class="inline-flex items-center max-w-max">
					<p
						class="text-gray-600 font-medium leading-relaxed"
					>
						Monthly
					</p>
					<div class="mx-3">
						<input
							type="checkbox"
							class="toggle text-primmary toggle-primary"
							bind:checked={isAnualPricing}
						/>
					</div>
					<p
						class="text-primary font-medium leading-relaxed"
					>
						<span>Yearly</span>
						<span
							class="text-primary font-semibold"
							>(Save 20%)</span
						>
					</p>
				</div>
			</div>
		</div>
		<div
			class="overflow-hidden border border-blueGray-200 rounded-3xl"
		>
			<div
				class="flex flex-wrap divide-y md:divide-x lg:divide-y-0 divide-blueGray-200"
			>
				{#each pricingTable as tier}
					<div
						class="w-full md:w-1/2 lg:w-1/4 text-black"
					>
						<div
							class="px-9 pt-8 pb-11 h-full bg-white bg-opacity-90"
							style="backdrop-filter: blur(46px);"
						>
							<span
								class=" mb-3  text-sm text-primary font-semibold uppercase tracking-px leading-snug"
								>{tier.tier}</span
							>
							<p
								class="mb-6 text-gray-500 font-medium dark:text-black leading-relaxed"
							>
								{tier.description}
							</p>
							<h3
								class="mb-1 text-4xl text-gray-900 font-bold leading-tight"
							>
								{#if isAnualPricing}
									<span
										>{formatter.format(
											tier.annualPricing
										)}</span
									>
								{:else}
									<span
										>{formatter.format(
											tier.monthlyPricing
										)}</span
									>
								{/if}
								<span
									class="text-gray-400"
									>/mo</span
								>
							</h3>
							<p
								class="  mb-8 text-sm text-gray-500 font-medium leading-relaxed"
							>
								{tier.c2a}
							</p>
							<button
								on:click={() =>
									openPlans()}
								disabled={!tier.active}
								class="mb-9 btn {tier.popular
									? 'btn-primary'
									: 'btn-outline'} w-full"
								>{tier.active
									? "Get started now"
									: "Coming soon"}</button
							>
							<ul>
								{#each tier.features as feature}
									<li
										class="mb-4 flex gap-2"
									>
										<div
											class="min-w-8 flex justify-center"
										>
											<Icon
												type="CheckIconOutline"
											/>
										</div>
										<p
											class="font-semibold leading-normal"
										>
											{feature}
										</p>
									</li>
								{/each}
								{#each tier.missing as missingFeature}
									<li
										class="mb-4 gap-2 flex text-gray-400 "
									>
										<div
											class="min-w-8 flex justify-center"
										>
											<Icon
												type="CheckIconOutline"
											/>
										</div>
										<p
											class="font-semibold leading-normal"
										>
											{missingFeature}
										</p>
									</li>
								{/each}
							</ul>
						</div>
					</div>
				{/each}
				<div
					class="w-full text-black md:w-1/2 lg:w-1/4"
				>
					<div
						class="px-9 pt-8 pb-11 h-full bg-white bg-opacity-90"
						style="backdrop-filter: blur(46px);"
					>
						<span
							class=" mb-3  text-sm text-primary font-semibold uppercase tracking-px leading-snug"
							>{enterpriseColumn.tier}</span
						>
						<p
							class="mb-6 text-gray-500 dark:text-black font-medium leading-relaxed"
						>
							{enterpriseColumn.description}
						</p>
						<h3
							class="mb-1 text-4xl text-gray-900 font-bold leading-tight"
						>
							Contact us
						</h3>
						<p
							class=" mb-8 text-sm text-gray-500 font-medium leading-relaxed"
						>
							for a custom offer
						</p>
						<a href="#contact">
							<button
								class="mb-9 btn {enterpriseColumn.popular
									? 'btn-primary'
									: 'btn-outline'} w-full"
								>Get started now</button
							>
						</a>
						<ul>
							{#each enterpriseColumn.features as feature}
								<li
									class="mb-4 flex gap-2"
								>
									<div
										class="min-w-8 flex justify-center"
									>
										<Icon
											type="CheckIconOutline"
										/>
									</div>
									<p
										class="font-semibold leading-normal"
									>
										{feature}
									</p>
								</li>
							{/each}
							{#each enterpriseColumn.missing as missingFeature}
								<li
									class="mb-4 gap-2 flex text-gray-400 "
								>
									<div
										class="min-w-8 flex justify-center"
									>
										<Icon
											type="CheckIconOutline"
										/>
									</div>
									<p
										class="font-semibold leading-normal"
									>
										{missingFeature}
									</p>
								</li>
							{/each}
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
