import Input from "$lib/components/Input.svelte";
import Textarea from "$lib/components/Textarea.svelte";
import Switch from "$lib/components/Switch.svelte";
import Imageinput from "$lib/components/Imageinput.svelte";

export default {
  input: Input,
  textarea: Textarea,
  switch: Switch,
  imageinput: Imageinput,
};
