<script lang="ts">
  import Transactionitem from "./Transactionitem.svelte";
  import Title from "$lib/components/Title.svelte";
  import { fade } from "svelte/transition";
  import { useQuery } from "@sveltestack/svelte-query";
  import ProfileService from "../services/Profile.service";
  import { supabase } from "$lib/db";
  import Loading from "$lib/components/Loading.svelte";
  import Icon from "$lib/components/Icon.svelte";

  const user = supabase.auth.user();
  const profileService = new ProfileService();
  const transactions = useQuery(["transactions", user.id], () =>
    profileService.getTransactions(user.id)
  );
</script>

<span in:fade|local={{ duration: 100 }}>
  <Title
    style="mx-auto md:mx-0  justify-between px-7 py-3 max-w-md text-left text-xl font-semibold"
  >
    Transactions <Icon type="SwitchHorizontalIconOutline" />
  </Title>
  <ul class=" lg:mx-0 mx-auto max-w-md flex pb-52 px-7 flex-col gap-3">
    {#if $transactions.isLoading}
      <Loading />
    {:else if $transactions.isError}
      <div class="p-4 rounded-xl bg-base-300">Something went wrong</div>
    {:else if $transactions.data.length}
      {#each $transactions.data as transaction, index (index)}
        <Transactionitem {transaction} />
      {/each}
    {:else}
      <div class="alert text-center justify-center">No transactions yet</div>
    {/if}
  </ul>
</span>
