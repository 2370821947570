<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import JSConfetti from "js-confetti";
  import { melon } from "$lib/stores/systemModals.store";

  export let qrIsVisible;
  export let isBusinessPage;
  export let democonfetti;
  export let voucherExist;
  export let demo;
  export let confetti;

  let canvas;
  let jsConfetti = new JSConfetti({ canvas });

  onMount(() => {
    jsConfetti = new JSConfetti({ canvas });
  });

  const startConfetti = async () => {
    for (let i = true; i == true; true) {
      await jsConfetti.addConfetti({
        emojis: confetti,
        // emojis: ["🍱", "🍣", "🍥"],
        confettiRadius: 100,
        confettiNumber: 50,
      });
      jsConfetti.clearCanvas();
    }
  };

  onDestroy(() => {
    jsConfetti.clearCanvas();
  });

  $: if (democonfetti == false) {
    onMount(() => {
      jsConfetti.clearCanvas();
    });
  }

  $: if (
    ($voucherExist?.data?.length && isBusinessPage) ||
    (demo && democonfetti)
  ) {
    if (isBusinessPage || democonfetti) {
      // alert('exist')
      setTimeout(() => {
        // melon.alert({ message: "", title: "melon exist" });
        startConfetti();
      }, 300);
    }
  }
</script>

<canvas
  bind:this={canvas}
  class="{qrIsVisible
    ? 'hidden'
    : ''} z-0 rounded-[35px] pointer-events-none select-none confetti absolute top-0 left-0 h-full w-full"
/>
