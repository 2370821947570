import type { Writable } from "svelte/store";
import { writable } from "svelte/store";
import { v4 as uuidv4 } from "uuid";

interface IConfirm {
  title: string;
  message: string;
  yes: any;
  no: any;
}

interface INotification {
  [key: string]: any;
  message: string;
  type: string;
}

interface IModal {
  [key: string]: any;
  title: string;
  message: string;
  yes?: any;
  no?: any;
  type: "alert" | "confirm" | "menu";
  widgetTree?: any[];
}

type Menu = {
  widgetTree: any[];
  title: string;
  message: string;
};

type WidgetTree = {};
type Widget = "";
type Alert = {
  hideOK?: boolean;
  wide?: boolean;
  color?: string;
  widgetTree?: any[];
  title?: string;
  message: string;
};

export const melon = {
  notification: ({ message, type }) => {
    //todo
  },
  alert: ({
    wide,
    color,
    hideOK,
    title = "Alert",
    message,
    widgetTree,
  }: Alert) => {
    const type = "alert";
    const uuid = uuidv4();
    console.log(uuid); // for example "36b8f84d-df4e-4d49-b662-bcde71a8764f"
    modals.update((m) => [
      ...m,
      { hideOK, wide, color, title, message, type, widgetTree, id: uuid },
    ]);
  },
  menu: ({ title, message, widgetTree }: Menu) => {
    const type = "menu";
    const uuid = uuidv4();
    modals.update((m) => [
      ...m,
      { title, message, type, widgetTree, id: uuid },
    ]);
  },
  confirm: ({ title, message, yes, no }) => {
    const type = "confirm";
    const uuid = uuidv4();
    modals.update((m) => [...m, { title, message, type, yes, no, id: uuid }]);
  },
};

export const modals: Writable<IModal[]> = writable([]);
export const notifications: Writable<INotification[]> = writable([]);
