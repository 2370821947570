<script lang="ts">
  import Wallet from "$lib/features/wallet/components/Wallet.svelte"; import {
    useQuery,
    type UseQueryStoreResult,
  } from "@sveltestack/svelte-query";
  import Icon from "$lib/components/Icon.svelte";
  import Observer from "$lib/components/Observer.svelte";
  import ProfileWalletTransactions from "./ProfileWalletTransactions.svelte";
  import ProfileService from "../services/Profile.service";
  import { navigate } from "svelte-routing/src/history";
  import { user } from "$lib/stores/auth.store";
  import { supabase } from "$lib/db";

  const profileService = new ProfileService();
  let profile;
  let activeCards = true;
  let selectedCardType = "all";
  let selectedCategory = "food";
  const authUser = supabase.auth.user();
  $: if (!authUser) {
    navigate("/signin");
  }

  $: if ($user) {
    profile = useQuery(["profile", $user.id], () =>
      profileService.getProfile($user.id)
    );
  }
</script>

<div class="  relative flex-auto right-0 top-0 z-10 flex min-h-screen w-full">
  {#if $user}
    <div
      class="  lg:mt-0 relative space-y-7 flex flex-col container min-h-screen mx-auto flex-auto"
    >
      <h1 class=" h-8 w-full flex items-center px-7 text-xl font-semibold">
        Wallet
        <span class="flex-auto" />
        <Icon type="CollectionIconOutline" />
      </h1>
      <div class="px-7">
        <select
          bind:value={activeCards}
          name=""
          id=""
          class="text-md min-w-0 py-1 h-auto min-h-0 select"
        >
          <option value={true}>Saved</option>
          <option value={false}>Archived</option>
        </select>
      </div>

      <Observer threshold={0.3}>
        <div class="w-full flex">
          {#key activeCards}
            <Wallet bind:activeCards bind:selectedCategory />
          {/key}
        </div>
      </Observer>
      <Observer threshold={0.01}>
        <ProfileWalletTransactions />
      </Observer>
    </div>
  {/if}
</div>
