<script lang="ts">
  import { supabase } from "$lib/db";
  import { isOnline } from "$lib/stores/isOnline.store";
  import { Capacitor } from "@capacitor/core";

  // import { goto } from "@roxi/routify";
  import { businessCheck } from "src/utils/businessCheck";
    import { links } from "svelte-routing";
  import { fly } from "svelte/transition";
  import Icon from "./Icon.svelte";
  import Loading from "./Loading.svelte";
  let isNativePlatform = Capacitor.isNativePlatform();
  const user = supabase.auth.user();
</script>

{#if $isOnline && user}
  <div
    out:fly={{ y: 100 }}
    in:fly={{ y: 100 }}
    class="z-40 lg:hidden fixed backdrop-blur-sm bg-base-100 bg-opacity-90 border-t-[1px] dark:border-base-100 border-gray-100 w-full bottom-0 pb-safe tabnav"
  >
    <div class="h-12 w-full flex" use:links>
      <!-- <a -->
      <!--   href="/search/all" -->
      <!--   aria-label="explore" -->
      <!--   class="flex items-center flex-auto justify-center" -->
      <!-- > -->
      <!--   <Icon type="GlobeIconOutline" /> -->
      <!-- </a> -->
      <!-- <a -->
      <!--   href="/search/all" -->
      <!--   aria-label="explore" -->
      <!--   class="flex items-center flex-auto justify-center" -->
      <!-- > -->
      <!--   <Icon type="GlobeIconOutline" /> -->
      <!-- </a> -->

      <a
        href="/profile"
        aria-label="wallet"
        class="flex items-center flex-auto justify-center"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          /></svg
        >
      </a>
      <a
        href="/vouchers"
        aria-label="vouchers"
        class="flex items-center flex-auto justify-center"
      >
        <span class="-rotate-45">
          <Icon type="TicketIconOutline" />
        </span>
      </a>

      {#if isNativePlatform}
        <a
          href="/scanner"
          aria-label="qr scanner"
          class="flex items-center flex-auto justify-center"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            ><path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
            /></svg
          >
        </a>
      {/if}

      <a
        href="/profile/settings"
        aria-label="vouchers"
        class="flex items-center flex-auto justify-center"
      >
        <Icon type="CogIconOutline" />
      </a>
      <!--
      {#await businessCheck()}
        <a class="flex items-center flex-auto justify-center" href="">
          <div class="w-6">
            <Loading />
          </div>
        </a>
      {:then}
        <a
          href="/dashboard"
          aria-label="vouchers"
          class="flex items-center flex-auto justify-center"
        >
          <Icon type="OfficeBuildingIconOutline" />
        </a>
      {/await}
      -->
    </div>
  </div>
{:else if user}
  <div
    out:fly={{ y: 100 }}
    in:fly={{ y: 100 }}
    class="fixed mb-3 bottom-0 z-20 px-4 w-full left-0 pointer-events-none"
  >
    <div
      class=" max-w-[700px] alert mx-auto shadow-xl mb-safe  flex  items-center py-2 min-h-16 "
    >
      <div class="w-8">
        <Icon type="StatusOfflineIconOutline" />
      </div>
      <span class="flex-auto !m-0 text-sm"
        >Your (new) stamps will be visible in your wallet when you're back
        online</span
      >
    </div>
  </div>
{/if}
