import { supabase } from "../../../db";

let user = supabase.auth.user();
supabase.auth.onAuthStateChange(() => {
  user = supabase.auth.user();
});
export default class BusinessService {
  async getLocations(businessId: string) {
    const { data, error } = await supabase
      .from("business_locations")
      .select("*")
      .eq("business_id", businessId);

    if (error) throw error;
    return data;
  }

  async getBusinessByName(name: string) {
    const { data, error } = await supabase
      .from("businesses")
      .select("*, type(*)")
      .eq("name", name)
      .single();

    if (error) throw error;

    if ("id" in data) return data;

    throw Error("something went wrong please try again");
  }

  async getStampcard(id: string) {
    const { data, error } = await supabase
      .from("cards")
      .select(
        `
		*,
		info:business_id(*)
		`
      )
      .eq("business_id", id)
      .eq("user_id", user.id)
      .single();
    if (error) throw error;
    if ("id" in data) {
      return data;
    } else {
      throw Error("could not load the stampcard");
    }
  }
}
