<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import Title from "$lib/components/Title.svelte";
  import { app_assets } from "$lib/constants";
  // import { send, receive } from "src/utils/crossfade";
  // import { params } from "@roxi/routify";
  export let params;
  export let business;
  export let title = null;
  let transparencyLevel = "ff";
</script>

<div class="  min-h-[450px] relative pt-[85px]">
  <div class="">
    <span class="absolute top-0 left-0 h-full w-full">
      <Image
        banner
        overlay={$business.data.id == 51 ? false : true}
        contrast={$business.data.text_color}
        primary_color={$business.data.primary_color}
        style="  rounded-none h-full w-full h-full"
        src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{$business
          .data.id}/banner/{$business.data.id}.png"
      />
      <div
        class=" h-1/4 w-full absolute bottom-0 left-0 bg-gradient-to-t from-base-100 to-transparent"
      />
    </span>
    <div class="lg:aspect-[4/1] aspect-[4/1] w-full !bg-cover" />
    <div class="text-white pt-safe absolute h-full w-full top-0">
      <div
        class="mx-auto !pt-10 w-full container flex-col flex items-center h-full"
      >
        <div class="h-28 mb-7">
          {#if $business.data.id == 51}
            <Image
              style="!object-contain  rounded-xl"
              src="{app_assets}/melonfull.png"
            />
          {:else}
            <Image
              style="!object-contain  mt-8 rounded-xl"
              src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{$business
                .data.id}/logo/{$business.data.id}.png"
            />
          {/if}
        </div>
        {#if $business.data.id == 51}
          <span />
        {:else}
          <Title
            padding={false}
            style=" mt-7 !text-3xl text-white text-{$business.data
              .text_color} ml-0 lg:text-[40px]  !py-0 w-full justify-center text-center capitalize"
          >
            {#if title}
              {title}
            {:else}
              {params.id}
            {/if}
          </Title>
        {/if}
      </div>
    </div>
    <div class="absolute -bottom-[52px] w-full h-36 pointer-events-none">
      <!-- <svg -->
      <!--   preserveAspectRatio="none" -->
      <!--   viewBox="0 0 32 1" -->
      <!--   xmlns="http://www.w3.org/2000/svg" -->
      <!--   style="fill: {$business.data.primary_color}{transparencyLevel}" -->
      <!--   class="  text-theme dark:text-dark-theme" -->
      <!--   ><path d="M16 1C4 1 0 0 0 0H32C32 0 28 1 16 1Z" /></svg -->
      <!-- > -->
    </div>
  </div>
</div>
