<script lang="ts">
  import { fade } from "svelte/transition";
  export let type: string = "" || "sub";
  export let padding = true;
  export let style = "";
</script>

<div class="pb-6">
  <h2
    in:fade|local={{ duration: 100 }}
    class={`${padding ? "px-7" : ""} ${style} ${
      type == "sub" ? "!text-xl" : ""
    }   flex items-center gap-3 text-3xl font-semibold  `}
  >
    <slot />
  </h2>
  <slot name="subtext" />
</div>
