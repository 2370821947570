<script lang="ts">
	import { supabase } from "$lib/db";
	import Fileinput from "$lib/components/Fileinput.svelte";
	import { resizeImage } from "src/utils/resizeimage";
	import { createEventDispatcher } from "svelte";
	type IImageType = "profile" | "thumbnail" | "banner" | "other";

	export let bucket;
	export let path;
	export let skippable = false;
	export let id;
	export let backbutton = false;
	export let placeholderText = "upload a picture";
	export let type: IImageType = "profile";
	const dispatch = createEventDispatcher();
	let files;
	let crop = { x: 0, y: 0 };
	let zoom = 1;
	const uploadImage = async () => {
		const file: any = await resizeImage(files[0], type);
		const { data, error } = await supabase.storage
			.from(bucket)
			.upload(path + id + ".png", file, { upsert: true });
		if (error) return alert(error.message);
		console.log(data);
		dispatch("uploadfinish");
	};
	const clearImage = () => {
		files = null;
	};
</script>

<Fileinput
	{type}
	{placeholderText}
	on:cancel={() => (files = undefined)}
	on:fileselect={(e) => (files = e.detail)}
/>
{#if skippable}
	<button
		disabled={files}
		class="btn btn-ghost"
		on:click={() => dispatch("skip")}>Skip</button
	>
{/if}

<slot />
<div class="grid gap-4 {backbutton ? 'grid-cols-2' : 'grid-cols-1'}">
	{#if backbutton}
		<button class="btn" on:click={() => dispatch("prev")}>go back</button>
	{/if}
	<button
		disabled={!files}
		class="btn btn-primary"
		on:click={() => uploadImage()}>Save picture</button
	>
</div>
