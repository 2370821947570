import "./app.postcss";
import App from "./App.svelte";
import { SplashScreen } from "@capacitor/splash-screen";
// import * as Sentry from "@sentry/svelte";
// import { BrowserTracing } from "@sentry/tracing";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';

SuppressLongpressGesture.activateService();
// import { inject } from '@vercel/analytics';
//
// inject();
// Sentry.init({
//   dsn: "https://63a3cb3ec71f4c5ca0dfd777b6de78a2@o4504588034441216.ingest.sentry.io/4504588040601601",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

CapacitorUpdater.notifyAppReady();
CapacitorUpdater.addListener('downloadComplete', () => {
	alert('new version available, reload the app for changes')
})
CapacitorUpdater.addListener('majorAvailable', (info) => {
  // alert('majorAvailable was fired', info.version);
});
SplashScreen.hide();
const app = new App({ target: document.body });

export default app;
