import { onMount } from "svelte";
import { writable } from "svelte/store";

const checkColorSchemePreference = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return true;
  } else {
    return false;
  }
};

export const isDarkMode = writable(false);

setTimeout(() => {
  isDarkMode.set(checkColorSchemePreference());
}, 300);

window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
  e.matches ? isDarkMode.set(true) : isDarkMode.set(false);
});
