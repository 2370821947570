<script lang="ts">
  import type { Icon as IIcon } from "src/utils/icontypes";
  import Icon from "$lib/components/Icon.svelte";
  import { getNotificationsContext } from "svelte-notifications";
  import { baseURL } from "$lib/constants";
  import { navigate } from "svelte-routing";
  export let location;
  export let businessName = "";
  export let business;
  export let params;

  interface IAction {
    icon: IIcon;
    link: string;
    text: string;
    self: boolean;
  }

  //   const businessService = new BusinessService();
  const { addNotification } = getNotificationsContext();
  const triggerNotification = (isSucces, msg) => {
    addNotification({
      text: msg,
      position: "top-center",
      type: isSucces ? "success" : "error",
      removeAfter: 2000,
    });
  };

  const shareData = {
    title: params.id,
    text: `${params.id} on Melon Cards`,
    url: encodeURI(`${baseURL}/page/${params.id}`),
  };

  const sharePage = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      // alert("copied to clipboard");
      triggerNotification(true, "copied to clipboard");
      navigator.clipboard.writeText(shareData.url);
    }
  };
  let actions: IAction[] = [];
  if (business.has_menu) {
    actions = [
      {
        icon: "PhoneIconOutline",
        link: `tel:${location.phone}`,
        text: "phone",
        self: true,
      },
      {
        icon: "NewspaperIconOutline",
        link: `menu`,
        text: "menu",
        self: true,
      },
      {
        icon: "MapIconOutline",
        link: `https://maps.google.com?q=${businessName.replace(
          " ",
          "%20"
        )} ${location.address.replace(" ", "%20")}`,
        text: "maps",
        self: true,
      },
    ];
  } else {
    actions = [
      {
        icon: "PhoneIconOutline",
        link: `tel:${location.phone}`,
        text: "phone",
        self: true,
      },
      {
        icon: "MapIconOutline",
        link: `https://maps.google.com?q=${businessName.replace(
          " ",
          "%20"
        )} ${location.address.replace(" ", "%20")}`,
        text: "maps",
        self: true,
      },
      // {
      // 	icon: "ShareIconOutline",
      // 	link: ``,
      // 	text: "share",
      // 	self: true,
      // },
    ];
  }
</script>

<div class=" flex max-w-full h-24 grid-cols-3 justify-between">
  {#each actions as action}
    {#if action.text == "menu"}
      <div class="col-span-1 gap-1 flex flex-col items-center justify-center">
        <button
          class="btn btn-circle border-1 border-black dark:border-gray-600 border-opacity-30 btn-ghost btn-lg"
          on:click={() => navigate(action.link)}
        >
          <Icon type={action.icon} />
        </button>
        <span class="text-xs capitalize">
          {action.text}
        </span>
      </div>
    {:else}
      <div class="col-span-1 gap-1 flex flex-col items-center justify-center">
        <button
          class="btn btn-circle border-1 border-black dark:border-gray-600 border-opacity-30 btn-ghost btn-lg"
          on:click={() =>
            window.open(`${action.link}`, action.self ? "_blank" : "")}
        >
          <Icon type={action.icon} />
        </button>
        <span class="text-xs capitalize">
          {action.text}
        </span>
      </div>
    {/if}
  {/each}
  <div class="col-span-1 gap-1 flex flex-col items-center justify-center">
    <button
      class="btn btn-circle border-1 border-black dark:border-gray-600 border-opacity-30 btn-ghost btn-lg"
      on:click={() => sharePage()}
    >
      <Icon type={"ShareIconOutline"} />
    </button>
    <span class="text-xs capitalize"> share </span>
  </div>
  <!-- <div class="col-span-1 flex gap-1 flex-col items-center justify-center "> -->
  <!-- 	<button -->
  <!-- 		on:click={() => -->
  <!-- 			$goto("page/[type]/[id]/jobs", { -->
  <!-- 				id: $params.id, -->
  <!-- 			})} -->
  <!-- 		class="btn btn-circle border-1 border-black  dark:border-gray-600 border-opacity-30 btn-ghost btn-lg " -->
  <!-- 	> -->
  <!-- 		<Icon type="BriefcaseIconOutline" /> -->
  <!-- 	</button> -->
  <!-- 	<span class="text-xs"> Jobs </span> -->
  <!-- </div> -->
</div>
