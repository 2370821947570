<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import Title from "$lib/components/Title.svelte";

  export let locations;
  export let selectedLocation;

  interface IOpeningtimes {
    open: string;
    close: string;
  }
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getTime = () => {
    const d = new Date();
    const currentHour = d.getHours();
    const currentMinutes =
      d.getMinutes() <= 9 ? "0" + d.getMinutes().toString() : d.getMinutes();

    return `${currentHour}:${currentMinutes}`;
  };
  const getDay = () => {
    let d = new Date();
    let dayName = days[d.getDay()];

    return dayName;
  };
  const checkIfOpen = (hours) => {
    hours as { open: string; close: string };

    let currentTime = parseInt(getTime().replace(":", "")),
      openTime = parseInt(hours.open.replace(":", "")),
      closeTime = parseInt(hours.close.replace(":", ""));

    if (!openTime || !closeTime) return false;

    if (openTime >= currentTime || closeTime <= currentTime) return;

    return true;
  };
</script>

<Title type="sub" padding={false}>Opening hours</Title>

<table class=" w-full">
  <thead>
    <tr class="">
      <th class=" flex items-center gap-2 pb-4 text-left">
        <span class="flex items-center gap-2 ">
          <Icon type="CalendarIconOutline" />
          Days
        </span>
      </th>
      <th class=" pb-4   text-left">
        <span class="flex items-center gap-2 ">
          <Icon type="ClockIconOutline" />
          Hours
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    {#each days as day}
      {@const hours = $locations.data.find((e) => e.id == selectedLocation)
        .openinghours[day.toLowerCase()]}

      {@const businessDay = Object.keys(
        $locations.data.find((e) => e.id == selectedLocation).openinghours
      )}

      {@const { open, close } = hours}

      {@const currentDay = businessDay.find(
        (d) => d === getDay().toLowerCase()
      )}

      <tr>
        <td class="relative capitalize">
          <div
            class={`h-2 w-2 animate-ping absolute -left-6 mt-[6.9px] rounded-full ${
              true
                ? currentDay == day.toLowerCase()
                  ? checkIfOpen({ open, close })
                    ? "bg-green-500 font-semibold"
                    : "bg-red-500 font-semibold"
                  : ""
                : ""
            }`}
          />
          <!-- {JSON.stringify(hours)} -->
          {day}
        </td>
        <td>{open} - {hours.close}</td>
      </tr>
    {/each}
  </tbody>
</table>
