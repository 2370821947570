import { supabase } from '../../../db'

export default class ProfileService {
  private user = supabase.auth.user()
  async getProfile(userId: string | null = this.user.id) {

    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", userId)
      .single();

    if (error) throw error;
    return data;
  }
  async getTransactions(userId: string) {

    let stampTransactions, voucherTransactions, redeemedVoucherTransactions

    let { data: stampData, error: stampError } = await supabase
      .from("stamps")
      .select("*, business:business_id(*)")
      .eq("user_id", userId)
      .order("created_at", { ascending: false })
      .limit(20);

    if (stampError) throw stampError;

    if (stampData) {
      stampTransactions = stampData.map((stamp) => ({
        ...stamp,
        type: "stamp added",
      }));
    }
    let { data: voucherData, error: voucherError } = await supabase
      .from("vouchers")
      .select("*, business:business_id(*)")
      .eq("user_id", userId)
      .order("created_at", { ascending: false })
      .limit(20);

    if (voucherError) throw voucherError;

    if (voucherData) {

      voucherTransactions = voucherData.map((voucher) => ({
        ...voucher,
        type: "voucher received",
      }));

    }

    if (voucherError) throw voucherError;

    if (voucherData) {

      redeemedVoucherTransactions = voucherData.filter(v => !!v.redeemed_at)
      console.log(redeemedVoucherTransactions)
      redeemedVoucherTransactions = redeemedVoucherTransactions.map((voucher) => {
        return {
          ...voucher,
          created_at: voucher.redeemed_at,
          type: "voucher used",
        }
      });

    }

    let allTransactions = [
      ...stampTransactions,
      ...voucherTransactions,
      ...redeemedVoucherTransactions,
    ];

    return allTransactions.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }
}
