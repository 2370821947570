
import { supabase } from '../lib/db'
export const businessCheck = async () => {

  const user = supabase.auth.user()

  if(!user) return false

  const { data, error } = await supabase
    .from("business_owners")
    .select("id")
    .eq("user_id", user.id);

  if (error) return false;

  if (data.length) return true

  return false
};
