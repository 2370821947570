<script lang="ts">
  import type { Icon as IIcon } from "src/utils/icontypes";
  import { supabase } from "$lib/db";
  import { businessCheck } from "src/utils/businessCheck";
  import { Capacitor } from "@capacitor/core";
  import ProfileHeader from "$lib/features/profile/components/ProfileHeader.svelte";
  import Icon from "./Icon.svelte";
  import { useQuery } from "@sveltestack/svelte-query";
  import { Link, link } from "svelte-routing";
  import { navigate } from "svelte-routing/src/history";
    import { user } from "$lib/stores/auth.store";

  // let user = supabase.auth.user();
  let isBusinessOwner = false;
  let businesses;

  // supabase.auth.onAuthStateChange(async (event, _) => {
    // user = supabase.auth.user();
  // });

  interface ISidebarpage {
    name: string;
    link: string;
    icon: IIcon;
  }

  const sidebarpages: ISidebarpage[] = [
    {
      name: "Wallet",
      link: "/profile",
      icon: "CollectionIconOutline",
    },

    {
      name: "Vouchers",
      link: "/vouchers",
      icon: "TicketIconOutline",
    },
    {
      name: "Settings",
      link: "/profile/settings",
      icon: "CogIconOutline",
    },
  ];

  const getBusinesses = async () => {
    const { data, error } = await supabase
      .from("business_owners")
      .select(
        `
          *,
          business:business_id(*)
        `
      )
      .eq("user_id", $user.id);
    if (error) throw error;
    return data;
  };
  const myBusinesses = useQuery("myBusinesses", getBusinesses);
  $: if ($user) {
    (async () => {
      businesses = useQuery("businesses", () => getBusinesses());

      isBusinessOwner = await businessCheck();
    })();
  }
</script>

{#key $user}
  {#if $user}
    <div class="  min-w-[200px] flex w-full">
      <ProfileHeader sidebar hideBackground />
    </div>
    <ul class="mx-auto gap-3 rounded-none menu mb-7  w-56 rounded-box">
      {#each sidebarpages as page}
        <Link to={page.link} let:active>
          <li>
            <a class="!rounded-xl  {active ? 'active' : ''}">
              <Icon type={page.icon} />
              {page.name}
            </a>
          </li>
        </Link>
      {/each}
    </ul>

    <div class="flex-auto" />
    {#if $myBusinesses.isSuccess}
      {#if $myBusinesses.data.find((entry) => entry.business.active == true)}
        <div class="px-8 py-7 flex flex-col gap-4 ">
          <button
            class="btn btn-primary"
            on:click={() => navigate("/dashboard/")}
            ><Icon type="ChartSquareBarIconOutline" /> Go to dashboard</button
          >
        </div>
      {/if}
    {/if}
  {/if}
{/key}
