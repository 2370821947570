<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import { app_assets, baseURL } from "$lib/constants";
  export let id;
</script>

<section {id} class="py-32 overflow-hidden">
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap">
      <div class=" w-full h-full flex-auto flex items-center md:w-1/2 p-8">
        <div
          class=" h-[900px] hidden lg:block flex-auto max-h-[800px] w-full max-w-[400px] mockup-phone"
        >
          <div class="invisible camera rounded-xl" />
          <div class="display h-full overflow-hidden relative bg-white">
            <iframe
              title="meloncards page"
              src="{baseURL}/page/melon%20cards"
              class="w-full h-full"
              frameborder="0"
            />
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <h2
          class="mb-20 text-4xl md:text-5xl font-black font-heading tracking-px-n leading-tight md:max-w-lg"
        >
          How Melon Cards helps you grow your business.
        </h2>
        <div class="flex flex-wrap -m-1.5">
          <div class="w-full p-1.5">
            <div class="flex flex-wrap -m-6">
              <div class="w-auto p-6">
                <div
                  class="relative mb-3 w-10 h-10 text-lg text-white font-bold rounded-full"
                >
                  <div class="bg-primary h-10 w-10 rounded-full" />
                  <span
                    class="absolute top-1/2 left-1/2 transform font-black -translate-x-1/2 -translate-y-1/2"
                    >1</span
                  >
                </div>
                <Image
                  style="relative !object-contain left-3"
                  src="{app_assets}/images/how-it-works/line.svg"
                  alt=""
                />
              </div>
              <div class="flex-1 p-6">
                <div class="md:max-w-xs">
                  <h3 class="mb-3 text-2xl font-bold leading-snug">Connect</h3>
                  <p class="text-gray-700 font-medium leading-relaxed">
                    Allow customers to scan their digital stamp card at your
                    store and collect stamps with each visit.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-1.5">
            <div class="flex flex-wrap -m-6">
              <div class="w-auto p-6">
                <div
                  class="relative -left-1 mb-3 w-10 h-10 text-lg text-white font-bold rounded-full"
                >
                  <div class="bg-primary h-10 w-10 rounded-full" />
                  <span
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 font-black -translate-y-1/2"
                    >2</span
                  >
                </div>
                <Image
                  style="relative !object-contain left-3"
                  src="{app_assets}/images/how-it-works/line2.svg"
                  alt=""
                />
              </div>
              <div class="flex-1 p-6">
                <div class="md:max-w-xs">
                  <h3 class="mb-3 text-2xl font-bold leading-snug">Reward</h3>
                  <p class="text-gray-700 font-medium leading-relaxed">
                    Reward customers with an enticing reward once their card is
                    full, encouraging loyalty and repeat visits.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-1.5">
            <div class="flex flex-wrap -m-6">
              <div class="w-auto p-6">
                <div
                  class="relative left-5 mb-3 w-10 h-10 text-lg text-white font-bold rounded-full"
                >
                  <div class="bg-primary h-10 w-10 rounded-full" />
                  <span
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 font-black -translate-y-1/2"
                    >3</span
                  >
                </div>
              </div>
              <div class="flex-1 p-6">
                <div class="md:max-w-xs">
                  <h3 class="mb-3 text-2xl font-bold leading-snug">Profit</h3>
                  <p class="text-gray-700 font-medium leading-relaxed">
                    Repeat customers tend to spend more over time. They are
                    familiar with your products or services, have built trust in
                    your brand, and are more likely to make higher-value
                    purchases
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
