<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  export let label = "";
  export let props;
  const dispatch = createEventDispatcher();

  const dispatchChange = (e) => {
    dispatch("change", e.target.checked);
  };
  let input;
  onMount(() => {
    input.indeterminate = true;
  });
</script>

<label class=" gap-4 cursor-pointer label">
  <input
    bind:this={input}
    type="checkbox"
    on:change={(e) => dispatchChange(e)}
    class="toggle toggle-primary"
    {...props}
  />
  <span class="label-text">{label}</span>
  <div class="flex-auto" />
</label>
