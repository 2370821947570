
// import Resizer from "react-image-file-resizer";
import Resizer from '../utils/resizer'
const resize = Resizer.imageFileResizer;
export const resizeImage = (file, type = 'profile') => {
	return new Promise((resolve, reject) => {

		if (type == 'profile') return resize(file, 500, 500, "PNG", 70, 0, (uri) => resolve(uri), "blob");
		if (type == 'banner') return resize(file, 1000, 500, "PNG", 90, 0, (uri) => resolve(uri), "blob");
		resize(file, 400, 800, "PNG", 70, 0, (uri) => resolve(uri), "blob")
	});
};
