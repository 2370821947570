import { supabase } from "../../../db";
import CardService from "../../card/services/Card.service";


export default class StampcardService extends CardService {
  constructor() {
    super();
  }
  private user = supabase.auth.user()

  async getBusinessType(businessName: string) {
    const { data, error } = await supabase
      .from("businesses")
      .select("type(name)")
      .eq("name", businessName.toLowerCase())
      .single();
    if (error) throw "";
    return data.type.name;
  };

  cardSubscription(cardId: any, cb: (arg0: number) => void) {
    let stamps = 0
    return supabase
      .from(`cards:id=eq.${cardId}`)
      .on("*", (payload) => {
        stamps = payload.new.stamps;
        cb(stamps)
      })
      .subscribe();
  }

  voucherSubscription(cardId: string, cb: (arg0: number) => void) {
    let updated = 0
    return supabase
      .from(`vouchers:card_id=eq.${cardId}`)
      .on("*", () => {
        updated++;
        cb(updated)
      })
      .subscribe();
  }
  async checkVoucherExist(businessId: string) {
    if (!this.user) return [];
    const { data, error } = await supabase
      .from("vouchers")
      .select("*")
      .eq("valid", true)
      .eq("user_id", this.user.id)
      .eq("business_id", businessId);
    if (error) throw error;
    return data;
  };

}
