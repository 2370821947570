<script lang="ts">
  import Icon from "$lib/components/Icon.svelte";
  import { onMount } from "svelte";
  import VanillaTilt from "vanilla-tilt";
  import { send, receive } from "src/utils/crossfade";
  // import { crossfade } from "svelte/transition";
  // const [send, receive] = crossfade({});
  let element;
  export let demo: boolean;
  let collapse: boolean;
  export let isBusinessPage;
  export let primary_color: string;
  export let active: boolean;
  export let business;
  export let qrIsVisible: boolean;

  onMount(() => {
    if (demo) {
      VanillaTilt.init(element, {
        max: 25,
        glare: true,
        "max-glare": 0.3,
        speed: 400,
      });
    }
  });
  $: if (qrIsVisible) {
    collapse = false;
  }
</script>

<div
  in:receive|global={{ key: business.id, duration: 500 }}
  out:send|global={{ key: business.id }}
  bind:this={element}
  class="transition-all max-w-[300px] relative {!collapse
    ? 'max-h-[500px]'
    : ''} relative"
>
  <div
  id="card_{business.id}"
    class="{active
      ? ''
      : 'grayscale shadow-none !bg-opacity-0'} disabled {business.max_stamps >=
    10
      ? collapse
        ? ''
        : 'aspect-[4/6] overflow-hidden'
      : ''} relative {isBusinessPage == false
      ? 'aspect-[4/6]'
      : ''} h-auto top-0 mx-auto stampcard transform-gpu flex min-h-[460px] min-w-[300px] shadow-xl dark:shadow-base-200 shadow-gray-300 w-full flex-col gap-3 {business.id ==
    57
      ? 'rounded-b-none'
      : ''} rounded-[35px] bg-gray-200 p-5 {collapse ? 'pb-0' : ''}"
    style={`color: ${primary_color};background: ${primary_color}; transform: perspective(1000px); transform-style: preserve-3d;`}
  >
    <slot {collapse} />
    {#if business.max_stamps >= 10 && qrIsVisible == false && (isBusinessPage || demo)}
      <div
        on:keyup
        style="color: {primary_color}"
        on:click={() => (collapse = !collapse)}
        class=" min-h-[54px] rounded-b-[35px] flex text-[{business.primary_color}] justify-center collapsbottom w-full {collapse ==
        true
          ? 'relative'
          : 'absolute'} bottom-0 z-10 left-0 flex flex-col mt-4"
      >
        <span
          style=" text-shadow: 0px 0px 1px rgba(0,0,0, .1);"
          class=" font-normal uppercase text-sm text-{business.text_color} flex flex-col justify-center items-center"
        >
          {!collapse ? "Show more" : "Collapse"}
          <Icon
            type={!collapse ? "ChevronDownIconOutline" : "ChevronUpIconOutline"}
          />
        </span>
      </div>
    {:else if business.max_stamps >= 10 && !qrIsVisible}
      <div
        on:keyup
        on:click={() => (collapse = !collapse)}
        style={`color: ${business.primary_color} !important;`}
        class="h-16 rounded-b-[35px] items-center justify-center uppercase collapsbottom w-full absolute bottom-0 z-10 left-0 flex flex-col"
      />
    {/if}
    <span class="bg-black border-black hidden" />
    <span class="bg-white border-white hidden" />
  </div>

  {#if business.id == 57}
    <div class="absolute w-full -mt-[22px]">
      <svg
        viewBox="0 0 1028 209"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="455" width="117" height="209" rx="41" fill="#CA9D84" />
        <path
          d="M309.619 2.87499e-05L1028 9.15527e-05L1028 162L520.627 98.8235L309.619 142.941L6.72641e-06 85.0588L1.41625e-05 1.68211e-06L309.619 2.87499e-05Z"
          fill="url(#paint0_linear_51_224)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_51_224"
            x1="514"
            y1="162"
            x2="514"
            y2="4.66174e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#46E0F4" />
            <stop offset="1" stop-color="#46F4A1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  {/if}
</div>

<style>
  .collapsbottom {
    box-shadow: inset 0 -100px 60px -80px;
  }
</style>
