<script lang="ts">
  import Appbar from "$lib/components/Appbar.svelte";
  import Title from "$lib/components/Title.svelte";
  import Image from "$lib/components/Image.svelte";
  import Confetti from "$lib/components/Confetti.svelte";
  import { supabase } from "$lib/db";
  import { useQuery } from "@sveltestack/svelte-query";
  import { format } from "timeago.js";
  import { checkIsOwner } from "src/utils/isOwner";
  import { navigate } from "svelte-routing/src/history";
  import { app_assets } from "$lib/constants";

  export let params;
  let lastStamp;
  let voucher;
  let success;

  const getLastStampAdded = async (business_id) => {
    console.log($fullCardDetails.data.user.id);
    const { data, error } = await supabase
      .from("stamps")
      .select("*")
      .limit(1)
      .eq("business_id", business_id)
      .eq("user_id", $fullCardDetails.data.user.id)
      .order("created_at", { ascending: false })
      .single();
    console.log("hoi");
    console.log(data);
    if (data == null && error) return {};
    if (error) throw error;
    return data;
  };
  const getVoucher = async (business_id) => {
    const { data, error } = await supabase
      .from("vouchers")
      .select("id")
      .eq("user_id", $fullCardDetails.data.user.id)
      .eq("valid", true)
      .eq("business_id", business_id)
      .single();

    console.log(business_id);
    console.log("check");
    if (data == null && error) return {};
    if (error) throw error;
    return data;
  };
  const getCard = async () => {
    const { data, error } = await supabase
      .from("cards")
      .select("*, user:user_id(*)")
      .eq("id", params.card_id)
      .single();
    console.log(error);
    if (error) throw error;
    console.log("get card");

    console.log(data);
    return data;
  };

  const addStamp = async (card_id) => {
    console.log(card_id);
    const { data, error } = await supabase.rpc("add_stamp", {
      card_id,
    });
    if (error) throw alert(error.message);
    success = true;
    console.log(data);
  };

  const redeemVoucher = async (voucher_id) => {
    const { data, error } = await supabase.rpc("redeem_voucher", {
      voucher_id,
    });
    if (error) throw error;
    success = true;
  };
  const fullCardDetails = useQuery(["cardDetails", params.card_id], () =>
    getCard()
  );
  // let isOwner = await checkIsOwner()

  $: if ($fullCardDetails.isFetched) {
    console.log("fetched");

    lastStamp = useQuery(["lastStamp", params.card_id], () =>
      getLastStampAdded($fullCardDetails.data.business_id)
    );
    voucher = useQuery(["voucher", params.card_id], () =>
      getVoucher($fullCardDetails.data.business_id)
    );
  }
</script>

<div class="flex flex-col w-full">
  <Appbar backbutton />
  <div class="mx-auto container h-screen space-y-7 !p-7 flex flex-col">
    <!-- {JSON.stringify($fullCardDetails.data)} -->
    <div class="card bg-base-200 max-w-md shadow-xl p-7">
      {#if success == null}
        {#if $fullCardDetails.isLoading}
          loading..
        {:else if $fullCardDetails.isError}
          something went wrong
        {:else}
          {#await checkIsOwner($fullCardDetails.data.business_id) then isOwner}
            {#if isOwner}
              <div class=" pb-10 w-32 mx-auto">
                <img
                  alt=""
                  class="rounded-full ring-4 aspect-square ring-primary !object-cover"
                  src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{$fullCardDetails
                    .data.user.id}/{$fullCardDetails.data.user
                    .id}.png?{new Date().getSeconds()}"
                />
              </div>
              <p>
                <b>User</b>: {$fullCardDetails.data.user.username ? $fullCardDetails.data.user.username : 'Anonymous'}
              </p>
              <p>
                <b>Member since</b>: {format($fullCardDetails.data.created_at)}
              </p>
              <p>
                <b>Current amount of stamps</b>: {$fullCardDetails.data.stamps}
              </p>
              <br />
              {#if $lastStamp.isLoading}
                loading..
              {:else if $lastStamp.isError}
                error
              {:else if Object.entries($lastStamp.data).length}
                <p>
                  <b>Last stamp added</b>: {format($lastStamp.data.created_at)}
                </p>
                {#if $voucher.isLoading}
                  loading..
                {:else if $voucher.isError}
                  voucher error
                {:else if Object.entries($voucher.data).length}
                  <button
                    class="btn btn-primary mt-4"
                    on:click={(e) => {
                      e.target.disabled = true;
                      e.target.innerHTML = "using voucher..";
                      redeemVoucher($voucher.data.id);
                    }}>Redeem voucher</button
                  >
                {:else}
                  <button
                    class="btn btn-primary text-white mt-4"
                    on:click={(e) => {
                      e.target.disabled = true;
                      e.target.innerHTML = "adding a stamp..";
                      addStamp($fullCardDetails.data.id);
                    }}>Add stamp</button
                  >
                {/if}
              {:else if $voucher.isLoading}
                loading
              {:else if $voucher.isError}
                error voucher
              {:else}
                <p>
                  <b>Last stamp added</b>: Never
                </p>
                <button
                  class="btn btn-primary mt-4"
                  on:click={(e) => {
                    addStamp($fullCardDetails.data.id);
                    e.target.disabled = true;
                    e.target.innerHTML = "adding a stamp..";
                  }}>Add stamp</button
                >
              {/if}
            {:else}
              <Title>You have no access</Title>
            {/if}
          {:catch}
            <Title>You have no access</Title>
          {/await}
        {/if}
      {:else if success == true}
        <Confetti />
        <h1 class="text-2xl text-center font-bold">
          Congrats, <span class="capitalize"
            >{$fullCardDetails.data.user.username}</span
          > received your stamp!
        </h1>
        <div class="py-8">
          <Image src="{app_assets}/succesrocket.png" alt="" />
        </div>
        <p class="py-4 text-center text-gray-500">
          Let your customer know that they've received a stamp!
        </p>
        <button on:click={() => navigate("/profile")} class="btn btn-primary"
          >Go back</button
        >
      {/if}
    </div>
  </div>
</div>
