<script lang="ts">
	import ProfitCalculator from "./ProfitCalculator.svelte";

	import Image from "$lib/components/Image.svelte";
	import { app_assets } from "$lib/constants";
	export let id;
</script>

<a {id}>
	<section  class="py-36	overflow-hidden">
		<div class="container px-4 mx-auto">
			<h1 class=" max-w-5xl mb-20 text-5xl font-black">
				Research shows that a 5% increase in customer
				retention results in a profit increase of 25% to
				95%
			</h1>
			<div class="w-full h-full	flex">
				<ProfitCalculator />
			</div>
		</div>
	</section>
</a>
