<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import Icon from "./Icon.svelte";
  import { supabase } from "$lib/db";
  import { Capacitor } from "@capacitor/core";
  import { navigate } from "svelte-routing/src/history";
  import { useQuery } from "@sveltestack/svelte-query";

  export let isFixed = false;
  export let color = "";
  export let textcolor = "";
  export let isAbsolute = false;
  export let searchbutton = true;
  export let backbutton = false;
  export let transparent = false;

  let user = supabase.auth.user();
  let profile;

  supabase.auth.onAuthStateChange(async (event, _) => {
    user = supabase.auth.user();
  });
  let currentUrl = window.location.pathname;

  const getProfile = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", user.id)
      .single();
    if (error) throw error;
    return data;
  };

  $: if (user) {
    profile = useQuery(["profile", user.id], () => getProfile());
  }

  $: scrollpos = 0;

  const threshold = 50;

  document.body.onscroll = (e) => {
    var doc = document.documentElement;
    var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    scrollpos = top;
  };
</script>

<div class="w-full">
  <div
    class="{isFixed
      ? 'fixed'
      : 'sticky'} px-7 navbar bg-opacity-90 backdrop-blur-xl {transparent
      ? scrollpos > threshold
        ? 'bg-base-100 border-b-[1px] border-base-100'
        : 'backdrop-blur-none border-transparent'
      : 'bg-base-100'} {isAbsolute ? 'absolute' : ''} bg-{color} {textcolor ==
    ''
      ? ''
      : scrollpos > threshold
      ? ''
      : 'text-' + textcolor}  w-full transition-all z-30 {Capacitor.isNative
      ? 'pt-safe'
      : 'pt-4'} top-0 px-5"
  >
    <div class=" mx-auto lg:max-w-[900px] flex-auto flex">
      <div class="h-8">
        <div class="py-0 dropdown">
          {#if currentUrl.includes("/page")}
            <button
              aria-label="back"
              tabindex="0"
              on:click={() => navigate("/profile")}
              class="btn btn-sm btn-ghost btn-circle"
            >
              <Icon type="ChevronLeftIconOutline" />
            </button>
          {:else if currentUrl !== "/signin"}
            {#if backbutton}
              <button
                aria-label="back"
                tabindex="0"
                on:click={() => window.history.back()}
                class="btn btn-sm btn-ghost btn-circle"
              >
                <Icon type="ChevronLeftIconOutline" />
              </button>
            {/if}
            {#if !user}
              <button
                aria-label="signin"
                tabindex="0"
                on:click={() => navigate("/signin")}
                class="!mt-0 btn btn-sm btn-ghost min-w-[100px]"
              >
                Sign in
              </button>
            {/if}
          {:else if backbutton}
            <button
              aria-label="back"
              tabindex="0"
              on:click={() => window.history.back()}
              class="btn btn-sm btn-ghost btn-circle"
            >
              <Icon type="ChevronLeftIconOutline" />
            </button>
          {/if}
        </div>
      </div>
      <div class="navbar-center flex-auto relative">
        <div
          role="button"
          tabindex="0"
          on:keydown
          class=" py-0 btn h-auto !px-0 btn-ghost btn-sm normal-case text-lg"
          on:click={() => {
            if (currentUrl.includes("/page")) {
              navigate("/profile");
            } else {
              window.history.back();
            }
          }}
        >
          {#if !user}
            <span />
          {:else if profile}
            <div class="flex gap-2 items-center">
              {#if $profile.isSuccess}
                {#if !backbutton}
                  <Image
                    src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/avatars/{$profile
                      .data.id}/{$profile.data.id}.png"
                    style="w-8 h-8 h-full !object-cover rounded-full"
                  />
                  <span
                    class="min-w-[200px] min-h-full items-center flex gap-1 capitalize"
                  >
                    Hi, {$profile.data.username ? $profile.data.username : 'Anonymous'}
                  </span
                  >
                {:else}
                  <span
                    class=" min-w-[200px] min-h-full items-center flex gap-1 capitalize"
                  >
                    Back
                  </span>
                {/if}
              {:else if $profile.isLoading}
                <div class="h-[20px]" />
              {/if}
            </div>
          {/if}
        </div>
      </div>
      <div>
        <div class="navbar-end lg:pt-3" />
      </div>
    </div>
  </div>
</div>
