<script lang="ts">
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import QRCodeStyling from "qr-code-styling";
  import { onDestroy, onMount } from "svelte";

  export let src: string;
  export let manual = false;
  export let color = "white";
  export let id;

  const getCorrectColor = () => {
    if (manual && $isDarkMode) {
      return "white";
    } else if (manual && !$isDarkMode) {
      return "black";
    } else {
      console.log("stampcard here" + color);
      return color;
    }
  };

  let _color = getCorrectColor();
  let qrCode;

  let qrElement;

  onMount(() => {
    qrCode = new QRCodeStyling({
      width: 260,
      height: 260,
      type: "svg",
      data: src,
      dotsOptions: {
        color: _color,
        type: "rounded",
      },
      backgroundOptions: {
        color: "transparent",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
    });
    qrCode.append(qrElement);
  });
</script>

{#key $isDarkMode}
  <div
    class="aspect-square w-full {manual
      ? 'flex items-center justify-center border-2 rounded-xl'
      : ''} pb-2"
  >
    <div bind:this={qrElement} {id} />
  </div>
{/key}
