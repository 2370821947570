<script lang="ts">
  import components from "$lib/components/system/componentlist";
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import { isOnline } from "$lib/stores/isOnline.store";
  import { createEventDispatcher } from "svelte";
  import FormBuilder from "./FormBuilder.svelte";

  export let widgetTree;

  const dispatch = createEventDispatcher();

  //   let newform = widgetTree[0].form.fields;
</script>

{#each widgetTree as widget, treeIndex}
  {#key $isDarkMode.toString() || $isOnline}
    {#if "form" in widget}
    {alert('!!')}
      <FormBuilder
        form={widget.form}
        on:close={() => {
          dispatch("close");
        }}
      />
    {:else}
      <svelte:component
        this={components[widget.name]}
        on:close={() => dispatch("close")}
        {...widget}
      />
    {/if}
  {/key}
{/each}
