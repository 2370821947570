<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  export let unobserve = true;
  export let threshold = 1;
  export let css = true;
  let isVisible = false;
  let observedElement;
  let observer;

  const dispatch = createEventDispatcher();
  $: if (observedElement != null) {
    observer.observe(observedElement);
  }
  let options = {
    root: null,
    rootMargin: "0px",
    threshold,
  };

  let callback = (element, observer) => {
    element = element[0];
    // element.isIntersecting ? (isVisible = true) : (isVisible = false);
    if (element.isIntersecting) {
      isVisible = true;
      dispatch("isVisible");
    } else {
      if (!unobserve) {
        isVisible = false;
      }
    }
  };

  onMount(() => {
    observer = new IntersectionObserver(callback, options);
  });
</script>

<span bind:this={observedElement}>
  {#if css}
    <span class={`${isVisible ? "" : "invisible"}`}>
      <slot />
    </span>
  {:else if !css && !isVisible}
    <slot />
  {:else if isVisible}
    <span in:fade|local={{ duration: 500 }}>
      <slot />
    </span>
  {:else}
    <slot name="placeholder" />
  {/if}
</span>
