import { supabase } from '../db'
import { writable } from "svelte/store";
import { navigate } from 'svelte-routing'

export const user = writable(supabase.auth.user())

supabase.auth.onAuthStateChange(async (event, _) => {
	if (event == "SIGNED_OUT") {
		user.set(supabase.auth.user())
	}

	if (event == "SIGNED_IN") {
		user.set(supabase.auth.user())
	}
	if (event == "PASSWORD_RECOVERY") {
		user.set(supabase.auth.user())
	}

	if (event == "USER_DELETED") {
		supabase.auth.signOut();
		user.set(supabase.auth.user())
	}
});
