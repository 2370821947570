<script lang="ts">
  import "/src/app.css";
  import Notifications from "svelte-notifications";
  import NotificationToast from "$lib/components/NotificationToast.svelte";
  import Navbar from "$lib/components/Navbar.svelte";
  import Appbar from "$lib/components/Appbar.svelte";
  import Appsidebar from "$lib/components/Appsidebar.svelte";
  import { Capacitor } from "@capacitor/core";
  import { user } from "$lib/stores/auth.store";
  export let noauthneeded = false;
  export let backbtn = false;
  const toast = NotificationToast as any;
</script>

<Notifications item={toast}>
  <div class="bg-base-100 top-0 absolute w-screen h-screen overflow-auto flex">
    <div
      class="min-w-[300px] border-r-[1px] border-base-200 dark:border-base-300 min-h-full max-h-screen hidden lg:flex flex-col"
    >
      {#if $user}
        <Appsidebar />
      {/if}
    </div>

    <div id="scroll-area" class="top-0 flex-auto overflow-y-auto h-screen">
      {#if Capacitor.isNative}
        <div class="pt-12 mt-safe lg:hidden">
          <Appbar isFixed backbutton={backbtn} />
        </div>
      {:else}
        <div class="mt-12 lg:hidden">
          <Appbar isFixed />
        </div>
      {/if}
      <div class="pt-10">
        <slot />
      </div>
      <Navbar />
    </div>
  </div>
</Notifications>

<style>
  #scroll-area {
    /* the #scroll-area must have position relative so we can position the #pull-to-refresh element relative to this */
    position: relative;

    /* attach scrollbar on overflow */
    overflow-y: auto;
  }
</style>
