<script lang="ts">
	import emailjs from "@emailjs/browser";
	import { onMount } from "svelte";
	import Image from "$lib/components/Image.svelte";
	import { melon } from "$lib/stores/systemModals.store";
	import { app_assets } from "$lib/constants";

	export let id: string;

	let submitBtn: string;
	let isSending = false;
	let from_name = "";
	let message = "";
	let email = "";
	let form: HTMLFormElement;

	const submitForm = (x: string | HTMLFormElement) => {
		if (!from_name.trim() || !message.trim() || !email.trim())
			return;
		submitBtn = "Sending...";
		isSending = true;

		const serviceID = "default_service";
		const templateID = "template_gwnrlk5";
		emailjs.init("jcsrLCS7XQgGhcJIp");
		emailjs.sendForm(serviceID, templateID, x).then(
			() => {
				submitBtn = "Send";
				isSending = false;

				form.reset();
				melon.alert({
					title: "Form submitted! Thank you! 🎉",
					message: "Thank you for submitting the form! We really appreciate your time and input. Have a wonderful day ahead!",
				});
			},
			(err) => {
				submitBtn = "Send";
				isSending = false;

				melon.alert({
					title: "Oops..",
					message: "Something went wrong while submitting the form, please try again later!",
				});

				alert(JSON.stringify(err));
			}
		);
	};
	onMount(() => {
		window.onloadTurnstileCallback = function () {
			turnstile.render(form, {
				sitekey: "0x4AAAAAAAH_f2xB_W11WOC4",
				callback: function (token) {},
			});
		};
		document.getElementById("form").addEventListener(
			"submit",
			function (event) {
				event.preventDefault();
				submitForm(form);
			}
		);
	});
</script>

<a {id}>
	<section class="relative py-36	overflow-hidden">
		<div class="relative z-10 container px-4 mx-auto">
			<div class="flex flex-wrap -m-8">
				<div class="w-full md:w-1/2 p-8">
					<div
						class="flex flex-col justify-between h-full"
					>
						<div
							class="mb-12 md:max-w-md block"
						>
							<p
								class="mb-6 text-sm text-primary uppercase tracking-px"
							>
								Contact us
							</p>
							<h1
								class="text-4xl md:text-6xl xl:text-10xl font-black font-heading tracking-px-n leading-none"
							>
								Get connected to
								grow better
								business.
							</h1>
							<div class="pt-5">
								<Image
									style="!object-contain"
									src="{app_assets}/image_contact.png"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="w-full md:w-1/2 p-8 ">
					<form
						bind:this={form}
						on:submit|preventDefault
						id="form"
						class="px-11 space-y-6 pt-8 pb-12 md:max-w-xl grid grid-col-1 ml-auto	rounded-3xl rounded-4xl shadow-gray-200 shadow-2xl"
					>
						<div
							class="form-control w-full"
						>
							<label
								for=""
								class="label"
							>
								<span
									class="label-text"
									>What is
									your
									name?</span
								>
							</label>
							<input
								bind:value={from_name}
								type="text"
								name="from_name"
								placeholder="First & Last name"
								class="input input-bordered w-full "
							/>
						</div>
						<div
							class="form-control w-full"
						>
							<label
								for=""
								class="label"
							>
								<span
									class="label-text"
								>
									What is
									your
									E-mail
									address?
								</span>
							</label>
							<input
								type="email"
								placeholder="E-mail"
								bind:value={email}
								name="reply_to"
								class="input input-bordered w-full "
							/>
						</div>

						<div
							class="form-control w-full"
						>
							<label
								for=""
								class="label"
							>
								<span
									class="label-text"
									>Message</span
								>
							</label>
							<textarea
								name="message"
								id="message"
								bind:value={message}
								placeholder="Type your message"
								class="input min-h-[200px] input-bordered w-full "
							/>
						</div>
						<div>
							<button
								disabled={isSending}
								class="btn btn-primary"
								>Send</button
							>
						</div>
						<div
							class="cf-turnstile"
							data-sitekey="0x4AAAAAAAH_f2xB_W11WOC4"
							data-callback="javascriptCallback"
						/>
					</form>
				</div>
			</div>
		</div>
	</section>
</a>
