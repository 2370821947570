<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import Icon from "../Icon.svelte";
  import WidgetTreeBuilder from "./WidgetTreeBuilder.svelte";
  export let title = "Alert";
  export let position: "" | "bottom" = "";
  export let message: string = "";
  export let type = "";
  export let widgetTree = [];
  export let wide = false;
  export let hideOK = false;
  export let color = "";
  export let manual = false;

  let alert;
  const dispatch = createEventDispatcher();
  onMount(() => {
    if (manual) return;
    alert.showModal();
  });
  const handleButtonClick = () => {
    dispatch("remove");
    setTimeout(() => {
      alert.close();
    }, 2000);
    // alert.close();
  };
</script>

<!-- Open the modal using ID.showModal() method -->
<dialog
  bind:this={alert}
  class="p-0 sm:modal-middle fixed left-0 modal modal-bottom"
>
  <div
    in:fade|local
    out:fade|local={{ duration: 300, delay: 300 }}
    on:keydown
    class="h-full w-full absolute bg-black bg-opacity-30 top-0 left-0"
    on:click={handleButtonClick}
  />
  <form
    in:fly|local={{ y: 600, delay: 200 }}
    out:fly|local={{ y: 600, duration: 300 }}
    method="dialog"
    style="background: {color} !important"
    class=" lg:scale-100 opacity-100 transition-none w-screen {wide
      ? '!max-w-[1000px] '
      : ''}   translate-y-0 mx-auto modal-box {position == 'bottom'
      ? 'pb-12'
      : ''}"
  >
    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
      ><Icon type="XIconOutline" /></button
    >
    <h3 class="text-base-content font-bold text-lg capitalize">
      {title}
    </h3>
    <p class="text-base-content py-4">
      {message}
    </p>
    <!-- <slot /> -->
    <WidgetTreeBuilder on:close={() => handleButtonClick()} {widgetTree} />
    {#if !hideOK}
      <div class="modal-action grid grid-cols-1">
        <button on:click={handleButtonClick} class="btn btn-primary"
          >Okay</button
        >
      </div>
    {/if}
  </form>
</dialog>
