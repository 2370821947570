<script lang="ts">
  import {
    persistQueryClient,
    createWebStoragePersistor,
  } from "@sveltestack/svelte-query";
  import { App } from "@capacitor/app";
  import { Router, Route, navigate } from "svelte-routing";
  import { QueryClient, QueryClientProvider } from "@sveltestack/svelte-query";
  import { supabase } from "$lib/db";
  import Rootlayout from "$lib/layouts/root.svelte";
  import Businesspagelayout from "$lib/layouts/Businesspagelayout.svelte";
  import SystemUiWrapper from "$lib/components/system/SystemUIWrapper.svelte";
  import Voucherspage from "$lib/features/voucherspage/components/Voucherspage.svelte";
  import Profilepage from "$lib/features/profile/components/Profilepage.svelte";
  import Businesspage from "$lib/features/businesspage/components/Businesspage.svelte";
  import Settingspage from "$lib/features/settings/components/Settingspage.svelte";
  import Changeusernamepage from "$lib/features/settings/components/Changeusernamepage.svelte";
  import Changeemailpage from "$lib/features/settings/components/Changeemailpage.svelte";
  import Changepasswordpage from "$lib/features/settings/components/Changepasswordpage.svelte";
  import Resetpasswordpage from "$lib/features/settings/components/Resetpasswordpage.svelte";
  import Changeprofilepicturepage from "$lib/features/settings/components/Changeprofilepicturepage.svelte";
  import Signin from "$lib/features/auth/components/Signin.svelte";
  import Landingpage from "$lib/features/landingpage/components/Landingpage.svelte";
  import Landingpagelayout from "$lib/features/landingpage/components/Landingpagelayout.svelte";
  import Newbusiness from "$lib/features/business/components/Newbusiness.svelte";
  import Signup from "$lib/features/auth/components/Signup.svelte";
  import Reset from "$lib/components/Reset.svelte";
  import Scanner from "$lib/features/scanner/components/Scanner.svelte";
  import Addstamp from "$lib/features/businesspage/components/Addstamp.svelte";
  import RedirectPage from "$lib/components/RedirectPage.svelte";
  import Menuindexpage from "$lib/features/menu/components/Menuindexpage.svelte";

  supabase.auth.onAuthStateChange(async (event, _) => {
    if (event == "SIGNED_OUT") {
      navigate("/signin");
    }

    if (event == "USER_UPDATED") {
    }

    if (event == "PASSWORD_RECOVERY") {
      navigate("/profile/settings/password/newpassword", { replace: true });
    }

    if (event == "USER_DELETED") {
      supabase.auth.signOut();
    }
  });

  App.addListener("appUrlOpen", function (event) {
    const slug = event.url.split(".app").pop();
    if (slug) navigate(slug);
  });

  let queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 8760, // 1 year
      },
    },
  });
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  });
</script>

<QueryClientProvider client={queryClient}>
  <SystemUiWrapper>
    <div id="melon" class="">
      <Router>
        <Route path="/page/:id" let:params>
          <Businesspagelayout>
            <Businesspage {params} />
          </Businesspagelayout>
        </Route>

        <Route path="/page/:id/addstamp/:card_id" let:params>
          <Businesspagelayout>
            <Addstamp {params} />
          </Businesspagelayout>
        </Route>

        <Route path="/page/:id/menu" let:params>
          <Businesspagelayout>
            <Menuindexpage {params} />
          </Businesspagelayout>
        </Route>

        <Route path="/vouchers">
          <Rootlayout>
            <Voucherspage />
          </Rootlayout>
        </Route>

        <Route path="/profile/settings">
          <Rootlayout backbtn>
            <Settingspage />
          </Rootlayout>
        </Route>

        <Route path="/profile/settings/email">
          <Rootlayout backbtn>
            <Changeemailpage />
          </Rootlayout>
        </Route>

        <Route path="/profile/settings/password/newpassword">
          <Rootlayout backbtn>
            <Changepasswordpage />
          </Rootlayout>
        </Route>

        <Route path="/profile/settings/resetpassword">
          <Reset noauthneeded>
            <Resetpasswordpage />
          </Reset>
        </Route>

        <Route path="/profile/settings/profilepicture">
          <Rootlayout backbtn>
            <Changeprofilepicturepage />
          </Rootlayout>
        </Route>

        <Route path="/profile/settings/username">
          <Rootlayout backbtn>
            <Changeusernamepage />
          </Rootlayout>
        </Route>

        <Route path="/scanner">
          <Reset noauthneeded>
            <Scanner />
          </Reset>
        </Route>

        <Route path="/signin">
          <Reset noauthneeded>
            <Signin />
          </Reset>
        </Route>

        <Route path="/signup">
          <Reset noauthneeded>
            <Signup />
          </Reset>
        </Route>

        <Route path="/business/new">
          <Reset noauthneeded>
            <Newbusiness />
          </Reset>
        </Route>

        <Route path="/business">
          <Landingpagelayout>
            <Landingpage />
          </Landingpagelayout>
        </Route>

        <Route path="/">
          <RedirectPage />
        </Route>

        <Route path="/profile">
          <Rootlayout>
            <Profilepage />
          </Rootlayout>
        </Route>
      </Router>
    </div>
  </SystemUiWrapper>
</QueryClientProvider>
