<script lang="ts">
  import fadescale from "src/utils/fadescale";
  import Stampcard from "$lib/features/stampcard/components/Stampcard.svelte";
  import Businesspagecontent from "./Businesspagecontent.svelte";
  import StampcardActionButtons from "$lib/features/stampcard/components/StampcardActionButtons.svelte";
  import Appbar from "$lib/components/Appbar.svelte";
  import { supabase } from "$lib/db";
  import { useQuery } from "@sveltestack/svelte-query";
  import Title from "$lib/components/Title.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Businessheader from "./Businessheader.svelte";
  import Openingtimes from "./Openingtimes.svelte";
  import { cubicInOut } from "svelte/easing";
  import Loading from "$lib/components/Loading.svelte";
  import AddtoWalletCard from "./AddtoWalletCard.svelte";
  import BusinessService from "$lib/features/business/services/Business.service";

  export let params;
  let stampcard;
  let locations;
  let selectedLocation;
  let user = supabase.auth.user();
  supabase.auth.onAuthStateChange(() => {
    user = supabase.auth.user();
    reloadCard = true
  });
  const businessService = new BusinessService();
  let reloadCard = false;

  const reloadStampcard = () => {
    stampcard = useQuery(
      ["stampcard", $business.data.id],
      () => businessService.getStampcard($business.data.id),
      {
        retry: 1, // Will retry failed requests 10 times before displaying an error
      }
    );
  };
  let business = useQuery(
    ["businessName", params.id],
    () => businessService.getBusinessByName(params.id),
    {
      retry: 1, // Will retry failed requests 10 times before displaying an error
    }
  );

  $: if (
    ($business.isSuccess == true ) ||
    reloadCard
  ) {
    stampcard = useQuery(
      ["stampcard", $business.data.id],
      () => businessService.getStampcard($business.data.id),
      {
        retry: 1, // Will retry failed requests 10 times before displaying an error
      }
    );

    locations = useQuery(

      ["locations", $business.data.id],
      () => businessService.getLocations($business.data.id),
      {
        retry: 1,
      }
    );
    reloadCard = false;
  }
  
</script>
<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Chewy&display=swap" rel="stylesheet">
</svelte:head>

<div id="business-{$business.isSuccess ? $business.data.id : ''}">
  <Appbar
    textcolor={$business.isSuccess
      ? $business.data.id == 51
        ? "black"
        : $business.data.text_color
      : "white"}
    backbutton
    transparent
    isFixed
  />

  <div class=" top-0 min-h-screen flex-auto w-screen">
    <div class="mx-auto min-h-screen pb-32">
      {#if $business.isLoading}
        <div class="h-screen w-full flex items-center justify-center">
          <Loading />
        </div>
      {:else if $business.isError}
        <Title>Page not found</Title>
      {:else if $business.data.active}
        <Businessheader {params} {business} />
        <div class="lg:px-7 px-0 w-full">
          <Businesspagecontent>
            <span class="lg:grid-cols-3" />
            <div
              class=" w-full px-12 pb-32 pt-7 lg:flex-row flex-col flex lg:gap-14 lg:min-w-[400px] grid-cols-1"
            >
              {#if $stampcard.isLoading}
                <div
                  class="w-full aspect-square flex items-center justify-center"
                >
                  <Loading />
                </div>
              {:else if $stampcard.isError}
                {#if $business.isSuccess}

                  <AddtoWalletCard
                  {params}
                    on:cardcreated={() => (reloadCard = true)}
                    {business}
                  />
                {/if}
              {:else if user}
                {#if $stampcard.data}
                  <div class="lg:sticky pb-12 top-20 self-start w-full">
                    <div
                      class="max-w-[280px] mx-auto justify-center flex relative"
                      in:fadescale|local={{
                        delay: 250,
                        duration: 500,
                        easing: cubicInOut,
                        baseScale: 0.5,
                      }}
                    >
                      <Stampcard
                        {params}
                        active={$stampcard.data.active}
                        isBusinessPage
                        on:delete={() => {
                          reloadCard = true;
                        }}
                        cardData={$stampcard.data}
                        business={$business.data}
                      />
                    </div>
                  </div>
                {/if}
              {:else}
                {#if $business.isSuccess}
                  <AddtoWalletCard
                  {params}
                    on:cardcreated={() => $stampcard.refetch()}
                    {business}
                  />
                {/if}
              {/if}
              <div
                class=" w-full space-y-12 flex-auto flex flex-col xl:p-7 xl:rounded-xl mx-auto {user
                  ? 'col-span-2'
                  : 'col-span-3'}   "
              >

                {#if $locations.isSuccess}
                  {#if $locations.data.length}
                    <div>
                      <Title type="sub" padding={false}>Select a location</Title
                      >
                      <select
                        id="locations"
                        bind:value={selectedLocation}
                        class="w-full select select-bordered"
                      >
                        {#each $locations.data as location}
                          <option value={location.id}>{location.address}</option
                          >
                        {/each}
                      </select>
                    </div>
                    {#if selectedLocation}
                      <StampcardActionButtons
                        {params}
                        business={$business.data}
                        businessName={$business.data.name}
                        location={$locations.data.find(
                          (e) => e.id == selectedLocation
                        )}
                      />
                      <!-- <div class=" overflow-hidden rounded-3xl"> -->
                      <!-- <Maps /> -->
                      <!-- </div> -->

                      <div class="">
                        <Title type="sub" padding={false}
                          ><span>
                            About <span class="capitalize">{params.id}</span>
                          </span>
                          <p
                            slot="subtext"
                            class=" pt-2 text-sm flex gap-2 items-center"
                          >
                            <Icon type="LocationMarkerIconOutline" />
                            {$locations.data.find(
                              (e) => e.id == selectedLocation
                            ).address}
                          </p>
                        </Title>
                        <p>
                          {@html $business.data.description}
                        </p>
                      </div>
                      <div class="">
                        <Openingtimes {locations} {selectedLocation} />
                      </div>
                    {/if}
                  {:else}
                    hoi
                  {/if}
                {/if}

              </div>
            </div>
          </Businesspagecontent>
        </div>
      {:else if !$business.data.active}
        <div class="container px-7">This business is no longer available.</div>
      {/if}
    </div>
  </div>
</div>
