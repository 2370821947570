<script lang="ts">
  import "/src/app.css";
  import Notifications from "svelte-notifications";
  import NotificationToast from "$lib/components/NotificationToast.svelte";
  import Navbar from "$lib/components/Navbar.svelte";
  import { navigate } from "svelte-routing";
  import { user } from "$lib/stores/auth.store";

  export let noauthneeded = false;

  const toast = NotificationToast as any;
  let menuIsOpen = false;

  $: if (!$user && noauthneeded == false) {
    navigate("/signin");
  }
</script>

<Notifications item={toast}>
  <div class="w-screen flex">
    <!-- <Appsidebar /> -->
    <div class="flex-auto">
      {#if noauthneeded}
        <slot />
      {/if}
      <Navbar />
    </div>
  </div>
</Notifications>
