import { registerPlugin } from '@capacitor/core';


export interface IModalPlugin {

	presentModal({ url }: { url: string }): void;
	dismissModalFromWeb({ url }: { url: string }): void;

}

const iOSModal = registerPlugin<IModalPlugin>('Modal');

export default iOSModal;
