

  type INotificationType = 'error' | 'success' | 'warning'
	export const createNotification = (context: any) => {
		return {
			dispatch: (type: INotificationType, message: string, removeAfter = 2500) => {
				
				const { addNotification } = context;
				addNotification({
					text: message,
					position: 'top-center',
					type,
					removeAfter
				});
			}
		}
	}
