<script lang="ts">
  import Appbar from "$lib/components/Appbar.svelte";
  import Title from "$lib/components/Title.svelte";
  import Image from "$lib/components/Image.svelte";
  import Businessheader from "$lib/features/businesspage/components/Businessheader.svelte";
  import BusinessService from "$lib/features/business/services/Business.service";
  import formMethods from "$lib/components/system/formMethods/index";
  import {
    useQuery,
    type UseQueryOptions,
    type UseQueryStoreResult,
  } from "@sveltestack/svelte-query";
  import Loading from "$lib/components/Loading.svelte";
  import Businesspagecontent from "$lib/features/businesspage/components/Businesspagecontent.svelte";
  import { fade } from "svelte/transition";
  import fadeScale from "src/utils/fadescale";
  import { supabase } from "$lib/db";
  import { melon } from "$lib/stores/systemModals.store";
  import Icon from "$lib/components/Icon.svelte";
  import type { HTMLInputTypeAttribute } from "svelte/elements";
  import createBeverageForm from "$lib/forms/createBeverage.form";
  import createFoodItemForm from "$lib/forms/createFoodItem.form";
  export let params;

  const businessService = new BusinessService();

  type FieldType = {
    [key: string]: any;
    id: string;
    name: string;
    type: HTMLInputTypeAttribute;
    required?: boolean;
    label?: string;
    placeholder?: string;
  };

  type FormTree = {
    method: (fields, cb) => Promise<void>;
    business_id?: number;
    fields: FieldType[];
  };

  let selectedTab: "Food" | "Bevarages" = "Food";

  const menucategories = [
    { name: "Food" as const, selected: true },
    { name: "Bevarages" as const, selected: false },
  ];

  const business = useQuery(["business", params.id], () =>
    businessService.getBusinessByName(params.id)
  );

  const selectTab = (index) => {
    for (const [i, cat] of menucategories.entries()) {
      if (!menucategories[i].selected) continue;
      menucategories[i].selected = false;
    }

    selectedTab = menucategories[index].name;
    menucategories[index].selected = true;
  };

  const getFood = async (id) => {
    const { data, error } = await supabase
      .from("menu_food")
      .select("*, item:food_id(*) ")
      .eq("business_id", id);
    if (error) throw melon.alert({ title: "Error", message: error.message });
    console.log(data);
    return data;
  };

  const getDrinks = async (id) => {
    const { data, error } = await supabase
      .from("menu_beverage")
      .select("*, item:beverage_id(*) ")
      .eq("business_id", id);
    if (error) throw melon.alert({ title: "Error", message: error.message });
    console.log(data);
    return data;
  };

  const openCreateMenuItem = () => {
    const widgetTree = [
      {
        name: "menuitem",
        visible: true,
        description: "Add new food",
        icon: "QrcodeIconOutline",
        action: () => openCreateFoodItem(),
      },
      {
        name: "menuitem",
        visible: true,
        description: "Add a new beverage",
        icon: "QrcodeIconOutline",
        action: () => openCreateBeverage(),
      },
    ];

    melon.menu({ title: "Add a new menu item", message: "", widgetTree });
  };

  const openCreateFoodItem = () => {
    melon.alert({
      title: "Add a new beverage",
      message: "",
      hideOK: true,
      widgetTree: [
        {
          name: "form",
          form: createFoodItemForm({ business_id: $business.data.id }),
        },
      ],
    });
  };
  const openCreateBeverage = () => {
    melon.alert({
      title: "Add a new beverage",
      message: "",
      hideOK: true,
      widgetTree: [
        {
          name: "form",
          form: createBeverageForm({ business_id: $business.data.id }),
        },
      ],
    });
  };
  //   createBeverage();
  let food;
  let beverages;

  $: if ($business.isSuccess) {
    food = useQuery(["food", $business.data.id], () =>
      getFood($business.data.id)
    );

    beverages = useQuery(["beverages", $business.data.id], () =>
      getDrinks($business.data.id)
    );
  }
</script>

<div class="flex flex-col w-full">
  <Appbar
    textcolor={$business.isSuccess
      ? $business.data.id == 51
        ? "black"
        : $business.data.text_color
      : "white"}
    backbutton
    transparent
    isFixed
  />
  {#if $business.isLoading}
    <Loading />
  {:else if $business.isError}
    something went wrong, please try again later
  {:else}
    <Businessheader {params} {business} />

    <Businesspagecontent>
      <div class="px-7 py-3 pt-7 flex">
        <button on:click={() => openCreateMenuItem()} class="btn btn-primary">
          <Icon type="PlusIconOutline" />
          New menu item</button
        >
      </div>
      <div
        class=" py-3 sticky top-14 z-10 bg-opacity-90 mt-8 backdrop-blur-xl bg-base-100 self-start px-7 w-full mx-auto"
      >
        <div class="tabs !bg-transparent tabs-boxed">
          {#each menucategories as cat, index}
            <button
              style="bg-[{$business.data.primary_color}] text-[{$business.data
                .text_color}] "
              on:click={() => selectTab(index)}
              class:tab-active={cat.selected}
              class="tab !bg-[{$business.data.primary_color}] text-[{$business
                .data.text_color}]">{cat.name}</button
            >
          {/each}
        </div>
      </div>
      <div
        class="menuitems max-w-5xl mx-auto gap-6 p-7 mt-8 grid grid-cols-2 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3"
      >
        {#key selectedTab}
          {#if selectedTab == "Food"}
            {#if $food.isLoading}
              <Loading />
            {:else if $food.isError}
              something went wrong
            {:else if $food}
              {#if $food.data.length}
                {#each $food.data as { item }, index}
                  <div
                    in:fadeScale={{ delay: 300 * index, duration: 500 }}
                    class="rounded-xl gap-7 bg-base-200 col-span-1 flex flex-col px-7 py-7"
                  >
                    <div class="">
                      <Image
                        style="aspect-square  shadow-xl rounded-full"
                        src="https://media-cdn.tripadvisor.com/media/photo-s/0f/34/c3/8b/photo0jpg.jpg"
                      />
                    </div>
                    <div class="space-y-3">
                      <p class="font-semibold text-sm line-clamp-2">
                        {item.name}
                      </p>
                      <p class="font-light line-clamp-2">
                        {item.description}
                      </p>
                      <p class="">${( item.price / 100 ).toFixed(2)}</p>
                      <button class="btn btn-primary w-full">Read more</button>
                    </div>
                  </div>
                {/each}
              {:else}
                No food on the menu yet
              {/if}
            {/if}
          {:else if $beverages.isLoading}
            <Loading />
          {:else if $beverages.isError}
            something went wrong
          {:else if $beverages}
            {#if $beverages.data.length}
              {#each $beverages.data as { item }, index}
                <div
                  in:fadeScale={{ delay: 300 * index, duration: 500 }}
                  class="rounded-xl gap-7 bg-base-200 col-span-1 flex flex-col px-7 py-7"
                >
                  <div class="">
                    <Image
                      style="aspect-square  shadow-xl rounded-full"
                      src="https://media-cdn.tripadvisor.com/media/photo-s/0f/34/c3/8b/photo0jpg.jpg"
                    />
                  </div>
                  <div class="space-y-3">
                    <p class="font-semibold text-sm">
                      {item.name}
                    </p>
                    <p class="font-light line-clamp-2">
                      {item.description}
                    </p>
                    <p class="">${( item.price / 100 ).toFixed(2)}</p>
                    <button class="btn btn-primary w-full">Read more</button>
                  </div>
                </div>
              {/each}
            {:else}
              no beverages yet
            {/if}
          {/if}
        {/key}
      </div>
    </Businesspagecontent>
  {/if}
</div>
