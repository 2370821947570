<script lang="ts">
  import Image from "$lib/components/Image.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Title from "$lib/components/Title.svelte";
  import { supabase } from "$lib/db";
  import { createNotification } from "src/utils/createNotification";
  import { getNotificationsContext } from "svelte-notifications";
  import { isDarkMode } from "$lib/stores/isDarkmode.store";
  import { app_assets } from "$lib/constants";
  import { navigate } from "svelte-routing/src/history";
  import { Link } from "svelte-routing";
  import { user } from "$lib/stores/auth.store";
  import Authmodal from "./Authmodal.svelte";

  const notificationContext = getNotificationsContext();
  const notification = createNotification(notificationContext);
  let loading = false;
  let email = "",
    password = "",
    // user = supabase.auth.user(),
    magiclink = false;

  const toggleMagiclink = () => {
    magiclink = !magiclink;
  };

  $: if ($user) navigate("/profile");

  const handleSignin = async () => {
    try {
      loading = true;
      if (magiclink) {
        const { error } = await supabase.auth.signIn({
          email,
        });
        if (error) throw error;
        notification.dispatch(
          "success",
          "We've send you a magic link, check your mail!"
        );
      } else {
        if (password) {
          const { error } = await supabase.auth.signIn({
            email,
            password,
          });
          if (error) throw error;
          notification.dispatch("success", "Signed in succesfully");
          navigate("/profile");
        }
      }
    } catch (error) {
      let errorMsg = error.error_description || error.message;

      notification.dispatch("error", errorMsg);
    } finally {
      loading = false;
    }
  };
  // supabase.auth.onAuthStateChange(async (event, _) => {
  // user = supabase.auth.user();
  // });
</script>

<!-- <Image src="/background_sonami.svg" style="select-none pointer-none absolute top-0 left-0"/> -->
<!-- <Appbar isFixed /> -->
<div class="w-full flex flex-col h-screen justify-center">
  <div class=" flex-col mx-auto w-full flex items-center pt-12 justify-center">
    <div class="w-32 mx-auto pb-7">
      {#if $isDarkMode}
        <Image
          style="!object-contain"
          src="{app_assets}/melonfulldarkmode.png"
        />
      {:else}
        <Image style="!object-contain" src="{app_assets}/melonfull.png" />
      {/if}
    </div>
    <form
      class=" hidden relative space-y-7 h-auto w-screen lg:w-full max-w-sm px-7 min-h-auto mx-auto lg:mt-4"
      on:submit|preventDefault={handleSignin}
    >
      <div class=" prose lg:prose-lg lg:text-left">
        <!-- <Title padding={false} style="w-full bg-red w00 !text-center">SONAMI</Title> -->
        <Title padding={false} type="sub">Sign in</Title>
      </div>
      <div class="form-control">
        <label for="email" class="label">
          <span class="label-text">E-mail</span>
        </label>
        <input
          bind:value={email}
          type="email"
          placeholder="E-mail"
          class="input input-bordered focus:outline-primary"
        />
      </div>
      {#if !magiclink}
        <div class="form-control">
          <label for="password" class="label">
            <span class="label-text">Password</span>
          </label>
          <input
            bind:value={password}
            type="password"
            placeholder="Password"
            class="input input-bordered focus:outline-primary"
          />
          <!-- <span class="label-text-alt mt-3 link link-hover">Forgot password?</span> -->
          <!-- <span class="label-text-alt mt-3 link link-hover">No account yet?</span> -->
        </div>
      {/if}
      <div class="mt-3">
        {#if !magiclink}
          <button
            type="button"
            class="border-none w-full my-3 mb-6 btn btn-secondary gap-4"
            on:click={() => toggleMagiclink()}
          >
            <!-- {#if !magiclink} -->
            <span class="w-full absolute left-1/2 -translate-x-1/2"
              >Sign in with a magic link</span
            >
            <span class="flex-auto" />
            <Icon type="SparklesIconOutline" />
            <!-- {:else} -->
            <!--	 Sign in with password -->
            <!-- {/if} -->
          </button>
        {/if}
        <button
          type="submit"
          disabled={loading}
          class="gap-12 btn w-full btn-primary"
          ><span class="absolute left-1/2 -translate-x-1/2"
            >{!magiclink ? "Sign in" : "Send magic link"}</span
          >
          <span class="flex-auto" />
          <Icon type="ArrowRightIconOutline" />
        </button>
        <div class="mt-6 text-sm text-center pb-4">
          {#if magiclink}
            <button
              on:click={() => (magiclink = !magiclink)}
              class="w-full dark:!text-black btn btn-primary mb-4 gap-4"
              ><Icon type="ArrowLeftIconOutline" />
              <span class="flex-auto" />
              <span class="absolute left-1/2 -translate-x-1/2"
                >Sign in with password</span
              ></button
            >
          {/if}
          <p>
            <Link to="/profile/settings/resetpassword" class=" font-semibold"
              >Forgot password?</Link
            >
          </p>
          <div class="flex items-center gap-4 h-12">
            <hr class="flex-auto" />
            or
            <hr class="flex-auto" />
          </div>
          <p class="">
            <span class="font-light">No account yet?</span>
            <Link to="/signup" class="font-semibold">Sign up for free</Link>.
          </p>
        </div>
      </div>
      <div class="form-control mt-6" />
    </form>
  </div>
</div>
<Authmodal/>

<style>
  .h-special {
    min-height: calc(100vh);
  }
</style>
