<script lang="ts">
  import Title from "$lib/components/Title.svelte";
  import { melon } from "$lib/stores/systemModals.store";
  import { supabase } from "$lib/db";
  import { onMount } from "svelte";
  import { getNotificationsContext } from "svelte-notifications";
  import { navigate } from "svelte-routing/src/history";

  const { addNotification } = getNotificationsContext();
  let email: string;
  const user = supabase.auth.user();
  email =  ''
  const getEmail = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select("email")
      .eq("id", user.id)
      .single();
    if (error)
      melon.alert({
        title: "Error",
        message:
          "Something went wrong while retrieving your email address. Please try again later.",
      });

    email = data.email;
  };

  onMount(async () => {
    await getEmail();
  });

  const handleResetemail = async () => {
    const { error, data } = await supabase.auth.update({
      email,
    });
    melon.alert({
      title: "Confirm your new email address",
      message: "Open your email to confirm the change of email.",
    });
    document.querySelector("form").reset();
    navigate("/");
  };
</script>

<div class="max-w-[920px] mx-auto container py-7 h-screen ">
  <div class="w-full max-w-md lg:mx-0 mx-auto">
    <Title>Change your e-mail address</Title>
    <form
      on:submit|preventDefault={handleResetemail}
      action=""
      class="mt-7 grid grid-cols-1 px-7 gap-4"
    >
      <div class="grid gap-2">
        <label for="newemail">New e-mail</label>
        <input
          class="input input-bordered focus:outline-primary"
          bind:value={email}
          type="email"
          placeholder="New e-mail"
        />
      </div>
      <div>
        <button class="btn btn-primary" type="submit">Save e-mail</button>
      </div>
    </form>
  </div>
</div>
