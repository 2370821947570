<script>
  import Image from "$lib/components/Image.svelte";
  import Loading from "$lib/components/Loading.svelte";
  import { receive, send } from "src/utils/crossfade";
  export let voucher;

  export let placeholder = false;
  const { business } = voucher;
  console.log(voucher);
</script>

<span class="text-white hidden" />
<span class="text-black hidden" />
{#if placeholder}
  <span />
  <!-- <Loading /> -->
{:else}
  <button
    in:receive|global={{ key: business.id, duration: 500 }}
    out:send|global={{ key: business.id }}
    tabindex="0"
    class="w-full btnpop h-auto flex-auto"
  >
    <li
      style="background: {business.primary_color || business.primary_color}"
      class="text-{business.text_color}  shadow flex w-full items-center pr-3 rounded-2xl"
    >
      <div
        class="w-24 min-w-[96px] flex justify-center items-center aspect-square"
      >
        <div class="w-16 p-2">
          <Image
            style="   rounded-none"
            src="https://yokkzdmjzoxtxelwvior.supabase.co/storage/v1/object/public/businesses/{business.id}/logo/{business.id}.png"
          />
        </div>
      </div>

      <div
        class=" text-left border-l-2 border-dotted border-base-100 flex-auto h-20 px-3 relative"
      >
        <div
          class="dot h-4 w-4 rounded-full bg-base-100 absolute -top-4 -left-2"
        />
        <div
          class="dot h-4 w-4 rounded-full bg-base-100 absolute -bottom-4 -left-2"
        />
        <b class="capitalize">{business.name || business.name}</b>
        <p class="line-clamp-2 max-w-[155px]">
          {@html business?.voucher_description || business?.voucher_description}
        </p>
      </div>
      <div class="relative min-w-[60px] pl-1 h-24">
        <button
          class="rotate-90 focus:rotate-90 uppercase left-0 top-6 absolute bg-white bg-opacity-10 font-semibold h-12 w-20 rounded-lg"
          >redeem</button
        >
      </div>
    </li>
  </button>
{/if}

<style>
  .btnpop:active {
    animation: button-pop var(--animation-btn, 0.25s) ease-out;
  }
</style>
